import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { RestDataSource } from './rest.datasource';


@Injectable()
export class AuthService {

    constructor(private datasource: RestDataSource) {}

   
    loginAuthenticate(value): Observable<boolean> {
        return this.datasource.loginAuthenticate(value);
    }
    ForgotPasswordService(value): Observable<boolean> {
        return this.datasource.ForgotPasswordService(value);
    }

    ChangePasswordService(value): Observable<boolean> {
        return this.datasource.ChangePasswordService(value);
    }

    ResetPasswordService(value,token): Observable<boolean> {
        return this.datasource.ResetPasswordService(value,token);
    }
    LogOut(): Observable<boolean> {
        return this.datasource.LogOut();
    }
    get authenticated(): boolean {
        return this.datasource.auth_token != null;
    }
    
    clear() {
        this.datasource.auth_token = null;
    }
}
