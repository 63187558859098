import { Routes } from '@angular/router';
import { AccountSuspendedComponent } from './account-suspended/account-suspended.component';

import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { CampaignNativeReportingCopyComponent } from "./CampaignNativeReportingCopy/campaignnativereportingcopy.component";
import { ErrorOneComponent } from './error/404/error-404.component';
import { errorPageComponent } from './error/errorpage/errorpage-component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'errorpage',
        pathMatch: 'full',
      },
      // {
      //   path: 'errorpage',
      //   loadChildren: () =>
      //     import('./session/session.module').then((m) => m.SessionModule),
      // },
      {
        path: 'authentication',
        redirectTo: 'errorpage',
        pathMatch: 'full',
      },
      {
        path: "usersharedpage/:_guid/:_token",
        component: CampaignNativeReportingCopyComponent,
      },
      {
        path: 'error',
        loadChildren: () =>
          import('./error/error.module').then((m) => m.ErrorModule),
      },
      {
        path: '404',
        component: ErrorOneComponent,
        //loadChildren: () => import('./Creative/creative.module').then(m => m.CreativeModule)
      },
      {
        path: 'errorpage',
        component: errorPageComponent,
      },
      {
        path: "r/:shortid",
        component: CampaignNativeReportingCopyComponent,
      },
      {
        path: "campusboxsharedpage/:_guid/:_token",
        component: CampaignNativeReportingCopyComponent,
      },
    ],
  },
  // {
  //   path: '**',
  //   redirectTo: 'session/404'
  // },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
     
      {
        path: "campaignperformance",
        component: CampaignNativeReportingCopyComponent,
      },
    
      {
        path: 'ChangePassword',
        component: ChangepasswordComponent,
        //canActivate: [AuthGuard]
      },
      {
        path: 'AccountSuspended',
        component: AccountSuspendedComponent,
      },
      {
        path: '404',
        component: ErrorOneComponent,
        //loadChildren: () => import('./Creative/creative.module').then(m => m.CreativeModule)
      },
      {path: '**', redirectTo: '/404'}
    ],
  },
];
