<div style="background:linear-gradient(90deg, #22A7F0 0%, #1393d8 100%);height: 110px;color:white">
    <div class="d-flex flex-row" style="padding: 20px 5px 5px 15px;ALIGN-ITEMS: CENTER;">
        <img alt="" pgRetina src="assets/img//Dashboard Icon Assets/Dashboard Welcome Icon.png" width="180" height="75">
        <div style="padding-left: 2%;">
            <h3> <strong><em>ACCOUNT SUSPENDED</em></strong></h3>
            <label>Please review details below.</label>
        </div>
    </div>
</div>
<br>
<div class="d-flex flex-row">
    <div style="width: 20%;"></div>

    <div style="width: 60%;text-align: center;">
        <i class="material-icons" style="font-size:140px;color:#E96025;">error_outline</i>
        <h2 style="color: #E96025;">Your Account Suspended</h2>

        <p style="font-size: 17px;">
            Your Dashboard access has been suspended due to a payment issue. <br> <b> Please click below to resolve
                payment. </b>
        </p>

        <div *ngIf="invoiceData.length>0">
            <div *ngFor="let row of invoiceData;index as i">
<div style="display: flex;justify-content: center;" *ngIf="row.invoicingLink!= null && row.invoicingLink != ''" >
    <div style="width:40%">
                <label style="display: inline-block;font-size: 20px;color: #3588cc;">
                    [{{row.LineItemName}}]</label>
                </div>
                <div  >
                <a [href]="row.invoicingLink" target="_blank"
                    *ngIf="row.invoicingLink!= null && row.invoicingLink != ''" style="padding-bottom: 5px;">

                    <button mat-raised-button type="button" class="btn btn-default"
                        style="background:linear-gradient(90deg, #22A7F0 0%, #1393d8 100%);color:white;margin-bottom:15px;font-size: 15px;">
                        PAY INVOICE </button>
                </a>
            </div>
            </div>
            
            </div>
        </div>
        <br>
        <p style="font-size: 17px;">If you're facing business challenges or financial hardship, please contact us and
            we're happy to discuss how we
            can work with you.</p>
        <a href="https://www.awarity.com/contact/main" target="_blank">
            <button mat-raised-button type="button" class="btn btn-default"
                style="background:linear-gradient(90deg, #22A7F0 0%, #1393d8 100%);color:white">
                CONTACT US</button>
        </a>


        <div style="width: 30%;"></div>
    </div>