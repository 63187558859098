<div class="row">
  <div class="col-md-6">
    <mat-card style="justify-content: center">
      <mat-card-title>
        <h6
          style="
            text-align: center;
            margin-top: 10px;

            font-weight: bolder;
          "
        >
          DESKTOP
        </h6>
      </mat-card-title>
      <!-- image start-->
      <div *ngIf="showSpinner">
        <!-- <img
          alt=""
          pgRetina
          src1x="assets/img/Native Reporting/Native Reporting Mockups V4_desktop_loading.jpg"
          src2x="assets/img/Native Reporting/Native Reporting Mockups V4_desktop_loading.jpg"
          src="assets/img/Native Reporting/Native Reporting Mockups V4_desktop_loading.jpg"
        /> -->
        <div class="pre-mapLoader" [class.hideOnLoad]="isTrue">
          <div class="sk-cube-mapgrid">
            <div class="sk-cube map1"></div>
            <div class="sk-cube map2"></div>
            <div class="sk-cube map3"></div>
            <div class="sk-cube map4"></div>
            <div class="sk-cube map5"></div>
            <div class="sk-cube map6"></div>
            <div class="sk-cube map7"></div>
            <div class="sk-cube map8"></div>
            <div class="sk-cube map9"></div>
          </div>
        </div>
      </div>

      <mat-card-content *ngIf="!showSpinner" style="display: flex">
        <div class="valuestylechangesmain">
          <img
            alt=""
            pgRetina="pgRetina"
            src1x="assets/img/Native Reporting/Desktop_Icon.png"
            src2x="assets/img/Native Reporting/Desktop_Icon.png"
            src="assets/img/Native Reporting/Desktop_Icon.png"
          />
          <br />
          <h6 style="color: #092e56; margin: 0px; font-weight: bolder">
            <img
              alt=""
              pgRetina="pgRetina"
              src1x="assets/img/Native Reporting/Impressions_Icon.png"
              src2x="assets/img/Native Reporting/Impressions_Icon.png"
              src="assets/img/Native Reporting/Impressions_Icon.png"
              style="height: 23px !important; width: 23px !important"
            />
            &nbsp; DIGITAL IMPRESSIONS
          </h6>
          <span
            *ngIf="
              this.DeskTopImpressions <= 0 ||
              this.DeskTopImpressions == undefined ||
              this.DeskTopImpressions == ''
            "
            >0</span
          >
          <span *ngIf="this.DeskTopImpressions > 0">{{
            this.DeskTopImpressions | number : 0
          }}</span>
        </div>

        <!-- <div style="padding-top: 4%">
          <h6 style="color: #092e56; margin: 0px; font-weight: bolder">
            <img
              alt=""
              pgRetina="pgRetina"
              src1x="assets/img/Native Reporting/Impressions_Icon.png"
              src2x="assets/img/Native Reporting/Impressions_Icon.png"
              src="assets/img/Native Reporting/Impressions_Icon.png"
              style="height: 23px !important; width: 23px !important"
            />
            &nbsp;TOTAL DIGITAL IMPRESSIONS
          </h6>
          <span
            style="margin-left: 35px"
            *ngIf="
              this.DeskTopImpressions <= 0 ||
              this.DeskTopImpressions == undefined ||
              this.DeskTopImpressions == ''
            "
            >0</span
          >
          <span style="margin-left: 35px" *ngIf="this.DeskTopImpressions > 0">{{
            this.DeskTopImpressions | number : 0
          }}</span>
       
        </div> -->

        <!-- <div *ngIf="this.showconversions == true">
          <h6 style="color: #e95f26; margin: 0px; font-weight: bolder">
            <img
              alt=""
              pgRetina="pgRetina"
              src1x="assets/img/Native Reporting/Icon_Conversions.png"
              src2x="assets/img/Native Reporting/Icon_Conversions.png"
              src="assets/img/Native Reporting/Icon_Conversions.png"
              style="height: 23px !important; width: 23px !important"
            />
            &nbsp;TOTAL CONVERSIONS
          </h6>
          <span
            style="margin-left: 35px"
            *ngIf="
              this.DeskTopTotalConversions <= 0 ||
              this.DeskTopTotalConversions == undefined ||
              this.DeskTopTotalConversions == ''
            "
            >0</span
          >
          <span
            style="margin-left: 35px"
            *ngIf="this.DeskTopTotalConversions > 0"
            >{{ this.DeskTopTotalConversions | number : 0 }}</span
          >
        </div> -->
        <!-- <div  *ngIf="this.showvtc == true">
                    <h6 style="color: #e95f26; margin: 0px; font-weight: bolder">
                        <img alt="" pgRetina="pgRetina" src1x="assets/img/Native Reporting/Icon_Conversions.png" src2x="assets/img/Native Reporting/Icon_Conversions.png" src="assets/img/Native Reporting/Icon_Conversions.png" style="height: 23px !important; width: 23px !important"/>
                        &nbsp;INDIRECT WEBSITE TRAFFIC
                    </h6>
                    <span style="margin-left: 35px;" *ngIf="this.DeskTopVTC <= 0 || this.DeskTopVTC == undefined || this.DeskTopVTC == '' ">0</span>
                    <span style="margin-left: 35px;" *ngIf="this.DeskTopVTC > 0 ">{{ this.DeskTopVTC | number:0}}</span>
                </div> -->
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-md-6">
    <mat-card style="justify-content: center">
      <mat-card-title>
        <h6
          style="
            text-align: center;
            margin-top: 10px;

            font-weight: bolder;
          "
        >
          TABLET
        </h6>
      </mat-card-title>
      <!-- image start-->
      <div *ngIf="showSpinner">
        <!-- <img
          alt=""
          pgRetina
          src1x="assets/img/Native Reporting/Native Reporting Mockups V4_tablet_loading.jpg"
          src2x="assets/img/Native Reporting/Native Reporting Mockups V4_tablet_loading.jpg"
          src="assets/img/Native Reporting/Native Reporting Mockups V4_tablet_loading.jpg"
        /> -->
        <div class="pre-mapLoader" [class.hideOnLoad]="isTrue">
          <div class="sk-cube-mapgrid">
            <div class="sk-cube map1"></div>
            <div class="sk-cube map2"></div>
            <div class="sk-cube map3"></div>
            <div class="sk-cube map4"></div>
            <div class="sk-cube map5"></div>
            <div class="sk-cube map6"></div>
            <div class="sk-cube map7"></div>
            <div class="sk-cube map8"></div>
            <div class="sk-cube map9"></div>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="this.showTabletUpsell == true">
         <img
         alt=""
         pgRetina
         src1x="assets/img/Native Reporting/Native Reporting Mockups V4_tablet_loading.jpg"
         src2x="assets/img/Native Reporting/Native Reporting Mockups V4_tablet_loading.jpg"
         src="assets/img/Native Reporting/Native Reporting Mockups V4_tablet_loading.jpg"
         />
     </div> -->
      <!-- image end -->
      <!-- <mat-card-content *ngIf="!showSpinner && this.showTabletUpsell == false"> -->
      <mat-card-content *ngIf="!showSpinner" style="display: flex">
        <div class="valuestylechangesmain">
          <!--  style="height: 118px !important; width: 192px !important" -->
          <img
            alt=""
            pgRetina="pgRetina"
            src1x="assets/img/Native Reporting/Tablet_Icon.png"
            src2x="assets/img/Native Reporting/Tablet_Icon.png"
            src="assets/img/Native Reporting/Tablet_Icon.png"
          />
          <br />
          <h6 style="color: #092e56; margin: 0px; font-weight: bolder">
            <img
              alt=""
              pgRetina="pgRetina"
              src1x="assets/img/Native Reporting/Impressions_Icon.png"
              src2x="assets/img/Native Reporting/Impressions_Icon.png"
              src="assets/img/Native Reporting/Impressions_Icon.png"
              style="height: 23px !important; width: 23px !important"
            />
            &nbsp; DIGITAL IMPRESSIONS
          </h6>
          <span
            *ngIf="
              this.TabletImpressions <= 0 ||
              this.TabletImpressions == undefined ||
              this.TabletImpressions == ''
            "
            >0</span
          >
          <span *ngIf="this.TabletImpressions > 0">{{
            this.TabletImpressions | number : 0
          }}</span>
        </div>
       
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-md-6">
    <mat-card style="justify-content: center">
      <mat-card-title>
        <h6
          style="
            text-align: center;
            margin-top: 10px;

            font-weight: bolder;
          "
        >
          MOBILE 
        </h6>
      </mat-card-title>
      <!-- image start-->
      <div *ngIf="showSpinner">
        <!-- <img
          alt=""
          pgRetina
          src1x="assets/img/Native Reporting/Native Reporting Mockups V4_Mobile_loading.jpg"
          src2x="assets/img/Native Reporting/Native Reporting Mockups V4_Mobile_loading.jpg"
          src="assets/img/Native Reporting/Native Reporting Mockups V4_Mobile_loading.jpg"
        /> -->
        <div class="pre-mapLoader" [class.hideOnLoad]="isTrue">
          <div class="sk-cube-mapgrid">
            <div class="sk-cube map1"></div>
            <div class="sk-cube map2"></div>
            <div class="sk-cube map3"></div>
            <div class="sk-cube map4"></div>
            <div class="sk-cube map5"></div>
            <div class="sk-cube map6"></div>
            <div class="sk-cube map7"></div>
            <div class="sk-cube map8"></div>
            <div class="sk-cube map9"></div>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="this.showMobileUpsell == true">
       <img
       alt=""
       pgRetina
       src1x="assets/img/Native Reporting/Native Reporting Mockups V4_Mobile_loading.jpg"
       src2x="assets/img/Native Reporting/Native Reporting Mockups V4_Mobile_loading.jpg"
       src="assets/img/Native Reporting/Native Reporting Mockups V4_Mobile_loading.jpg"
       />
   </div> -->
      <!-- image end -->
      <!-- <mat-card-content *ngIf="!showSpinner && this.showMobileUpsell == false"> -->
      <mat-card-content *ngIf="!showSpinner" style="display: flex">
        <div class="valuestylechangesmain">
          <!-- style="height: 118px !important; width: 193px !important" -->
          <img
            alt=""
            pgRetina="pgRetina"
            src1x="assets/img/Native Reporting/Mobile_Icon.png"
            src2x="assets/img/Native Reporting/Mobile_Icon.png"
            src="assets/img/Native Reporting/Mobile_Icon.png"
          />
          <h6 style="color: #092e56; margin: 0px; font-weight: bolder">
            <img
              alt=""
              pgRetina="pgRetina"
              src1x="assets/img/Native Reporting/Impressions_Icon.png"
              src2x="assets/img/Native Reporting/Impressions_Icon.png"
              src="assets/img/Native Reporting/Impressions_Icon.png"
              style="height: 23px !important; width: 23px !important"
            />
            &nbsp; DIGITAL IMPRESSIONS
          </h6>
          <span
            *ngIf="
              this.MobileImpressions <= 0 ||
              this.MobileImpressions == undefined ||
              this.MobileImpressions == ''
            "
            >0</span
          >
          <span *ngIf="this.MobileImpressions > 0">{{
            this.MobileImpressions | number : 0
          }}</span>
        </div>
       
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-md-6">
    <mat-card style="justify-content: center">
      <mat-card-title>
        <h6
          style="
            text-align: center;
            margin-top: 10px;

            font-weight: bolder;
          "
        >
          OTHERS
        </h6>
      </mat-card-title>
      <!-- image start-->
      <div *ngIf="showSpinner">
        <!-- <img
          alt=""
          pgRetina
          src1x="assets/img/Native Reporting/Native Reporting Mockups V4_otherdevice_loading.jpg"
          src2x="assets/img/Native Reporting/Native Reporting Mockups V4_otherdevice_loading.jpg"
          src="assets/img/Native Reporting/Native Reporting Mockups V4_otherdevice_loading.jpg"
        /> -->
        <div class="pre-mapLoader" [class.hideOnLoad]="isTrue">
          <div class="sk-cube-mapgrid">
            <div class="sk-cube map1"></div>
            <div class="sk-cube map2"></div>
            <div class="sk-cube map3"></div>
            <div class="sk-cube map4"></div>
            <div class="sk-cube map5"></div>
            <div class="sk-cube map6"></div>
            <div class="sk-cube map7"></div>
            <div class="sk-cube map8"></div>
            <div class="sk-cube map9"></div>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="this.showOthersUpsell == true">
 <img
 alt=""
 pgRetina
 src1x="assets/img/Native Reporting/Native Reporting Mockups V4_otherdevice_loading.jpg"
 src2x="assets/img/Native Reporting/Native Reporting Mockups V4_otherdevice_loading.jpg"
 src="assets/img/Native Reporting/Native Reporting Mockups V4_otherdevice_loading.jpg"
 />
</div>  -->
      <!-- image end -->
      <!-- <mat-card-content *ngIf="!showSpinner && this.showOthersUpsell == false"> -->
      <mat-card-content *ngIf="!showSpinner" style="display: flex">
        <div class="valuestylechangesmain">
          <!-- style="height: 118px !important; width: 193px !important" -->
          <img
            alt=""
            pgRetina="pgRetina"
            src1x="assets/img/Native Reporting/Other_Icon.png"
            src2x="assets/img/Native Reporting/Other_Icon.png"
            src="assets/img/Native Reporting/Other_Icon.png"
          />
          <h6 style="color: #092e56; margin: 0px; font-weight: bolder">
            <img
              alt=""
              pgRetina="pgRetina"
              src1x="assets/img/Native Reporting/Impressions_Icon.png"
              src2x="assets/img/Native Reporting/Impressions_Icon.png"
              src="assets/img/Native Reporting/Impressions_Icon.png"
              style="height: 23px !important; width: 23px !important"
            />
            &nbsp; DIGITAL IMPRESSIONS
          </h6>
          <span
            *ngIf="
              this.OthersImpressions <= 0 ||
              this.OthersImpressions == undefined ||
              this.OthersImpressions == ''
            "
            >0</span
          >
          <span *ngIf="this.OthersImpressions > 0">{{
            this.OthersImpressions | number : 0
          }}</span>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

