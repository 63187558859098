<div class="relative">
    <!-- <div class="form-bg" > -->
        <div class="errpage" >
        <div class="relative error-wrapper" >
            <!-- <h1>
                Oops!</h1> -->
            <!-- <span class="error-text">404</span> -->
            <!-- <h2> 
                Performance Unavailable</h2> -->

                <div style="text-align: center; margin-top:100px;">
                    <img  alt=""  pgRetina
                        src="assets/img/CampusBox/Campus Box Logo.png"
                    />

                </div>

                <h2 style="font-style: italic;">YOUR GEOFENCE PERFORMANCE IS CURRENTLY UNAVAILABLE</h2>
               
            <div class="error-details">
                <!-- <p>Share link has expired after 30 days, please contact sender for access.</p> -->
                <p> Email service@campusboxmedia.com for reporting access.</p>
            </div>
            <div class="error-actions">
                <!-- <button class="mat-blue" mat-raised-button>Take me to home</button> -->
            </div>
        </div>
    </div>
</div>