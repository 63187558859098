<!-- <style>
  .top-menu .horizontal-menu .main-h-list > li {
    display:contents;
}
</style> -->
<div class="app search-i" [dir]="layoutDir"
  [ngClass]="[ menuLayout, selectedSidebarImage, selectedSidebarColor, selectedHeaderColor, collapsedClass]"
  [class.app-dark]="dark" [class.boxed]="boxed" [class.collapsed-sidebar]="collapseSidebar"
  [class.compact-sidebar]="compactSidebar" [class.bg-img-disable]="!sidebarBg">

  <mat-sidenav-container class="app-inner">
    <!-- <mat-sidenav #sidemenu class="sidebar-panel" id="sidebar-panel" [mode]="isOver() ? 'over' : 'side'" 
      [opened]="!isOver()" (mouseover)="menuMouseOver()" (mouseout)="menuMouseOut()">
      <div class="opt-side relative ">
        <div class="sidebar-container ">
          <div class="branding text-center">
            <h2><i class="fa fa-superpowers" aria-hidden="true"></i> <span> OPTIMA</span>
            </h2>
            <div class="d-flex align-items-center">
              <img src="assets/img/SophosFullLogoWhite.svg" pgRetina src2x="assets/img/SophosFullLogoWhite.svg"
                alt="logo" class="brand" width="auto" height="45">

            </div>
          </div>
          <div class="main-nav">
            <div class="user-pro-wrap">
              <div class="user-profile-thumb text-center">
                <div class="user-i">
                  <img src="assets/images/userpic.jpg" class="rad-full mb-1" width="100" height="100" alt="">
                  <div> <span>John Doe</span></div>
                </div>
              </div> 
               <div class="most-used text-center">
                <a class="most-link" color="primary">
                  <mat-icon>home</mat-icon>
                </a>
                <a class="most-link" color="primary">
                  <mat-icon>person</mat-icon>
                </a>
                <a class="most-link" color="primary">
                  <mat-icon>settings</mat-icon>
                </a>
              </div>
            </div>
            <mat-nav-list appAccordion class="navigation relative">
              <mat-list-item appAccordionLink *ngFor="let menuitem of menuItems.getAll()">
                <a appAccordionToggle class="relative" mat-ripple [routerLink]="['/', menuitem.state]"
                  *ngIf="menuitem.type === 'link'">
                  <mat-icon>{{ menuitem.icon }}</mat-icon>
                  <span>{{ menuitem.name | translate }}</span>
                  <span fxFlex></span>
                  <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value
                    }}</span>
                </a>
                <a appAccordionToggle class="relative" mat-ripple href="{{menuitem.state}}"
                  *ngIf="menuitem.type === 'extLink'">
                  <mat-icon>{{ menuitem.icon }}</mat-icon>
                  <span>{{ menuitem.name | translate }}</span>
                  <span fxFlex></span>
                  <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value
                    }}</span>
                </a>
                <a appAccordionToggle class="relative" mat-ripple href="{{menuitem.state}}" target="_blank"
                  *ngIf="menuitem.type === 'extTabLink'">
                  <mat-icon>{{ menuitem.icon }}</mat-icon>
                  <span>{{ menuitem.name | translate }}</span>
                  <span fxFlex></span>
                  <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value
                    }}</span>
                </a>
                <a appAccordionToggle class="relative" mat-ripple (click)="onLogout()" target="_blank"
                  *ngIf="menuitem.type === 'Log Out'">
                  <mat-icon>{{ menuitem.icon }}</mat-icon>
                  <span>{{ menuitem.name | translate }}</span>
                  <span fxFlex></span>
                  <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value
                    }}</span>
                </a>

                <a appAccordionToggle class="relative" mat-ripple href="javascript:;" *ngIf="menuitem.type === 'sub'">
                  <mat-icon>{{ menuitem.icon }}</mat-icon>
                  <span>{{ menuitem.name | translate }}</span>
                  <span fxFlex></span>
                  <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value
                    }}</span>
                  <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
                </a>
                <mat-nav-list class="sub-menu" *ngIf="menuitem.type === 'sub'">
                  <mat-list-item *ngFor="let childitem of menuitem.children" routerLinkActive="open">
                    <a [routerLink]="['/', menuitem.state, childitem.state ]" class="relative" mat-ripple>{{
                      childitem.name | translate }}</a>
                  </mat-list-item>
                </mat-nav-list>
              </mat-list-item>
              <mat-divider></mat-divider>
              <mat-list-item>
                <a (click)="addMenuItem()">
                  <mat-icon>add</mat-icon>
                  <span>Add</span>
                </a>
              </mat-list-item>
            </mat-nav-list>
          </div>
        </div>
      </div>
      <div class="bg-overlay"></div>
    </mat-sidenav>   -->
    <mat-toolbar class="main-header main-header--sophos">
      <div class="horizontal-top-bar w-100">
        <div fxFlex.sm="100" fxFlex.xs="100" fxFlex.md="33.33" fxFlex.lg="33.33" fxFlex.xl="33.33" fxFlex="100">
          <!-- <div class="search-bar" fxFlex>
            <form class="search-form">
              <mat-icon>search</mat-icon>
              <input type="text" placeholder="Search" />
            </form>
          </div> -->
          <!-- <img src="assets/img/SophosFullLogoWhite.svg" pgRetina src2x="assets/img/SophosFullLogoWhite.svg"
                  alt="logo" class="brand brand-toolbar" height="101" width="32">
           &nbsp; -->
                  <!-- <img src="assets/images/Sophos Marketing Campaign Central.svg" pgRetina src2x="assets/images/Sophos Marketing Campaign Central.svg"
                  alt="logo" class="brand brand-toolbar" height="301" width="1000">  -->
                  <img src="assets/img/Awarity Campaign Central Logos/Awarity Campaign Central Lockup.svg" pgRetina src2x="assets/img/Awarity Campaign Central Logos/Awarity Campaign Central Logo Lockup.png"
                  alt="logo" class="brand brand-toolbar" height="301" width="1000" style="padding-left: 10px;
              ">
        </div>
        <!-- <div fxFlex.sm="100" fxFlex.xs="100" fxFlex.md="33.33" fxFlex.lg="33.33" fxFlex.xl="33.33" fxFlex="100">
          <div class="horizontal-logo">
            <div class="branding text-center">
              <h2><i class="fa fa-superpowers" aria-hidden="true"></i> <span> OPTIMA</span>
              </h2>
              <div class="d-flex align-items-center">
                

              </div>
            </div>
          </div>
        </div> -->
        <div fxFlex.sm="100" fxFlex.xs="100" fxFlex.md="33.33" fxFlex.lg="33.33" fxFlex.xl="33.33" fxFlex="100"></div>
        <div fxFlex.sm="100" fxFlex.xs="100" fxFlex.md="33.33" fxFlex.lg="33.33" fxFlex.xl="33.33" fxFlex="100">
          <div class="menu-links">
         
            <a href="mailto:support@awarity.com" ><button  mat-icon-button class="ml-xs overflow-visible" style="color: #ffffff; fill:#ffffff ">
              
              <i class='fas fa-comment' style="color:white;font-size:20px"></i>
              
            </button> </a> 
            <button [mat-menu-trigger-for]="user"   mat-icon-button class="ml-xs overflow-visible pull-right" style="color: #ffffff; fill:#ffffff ">
              <mat-icon>person</mat-icon>
            </button>
     
            <mat-menu #user="matMenu"  class="opt-menu" x-position="before">
              <div mat-menu-item class="head-menu grad-blue"> 
                {{this.currentUser[0].firstName }}&nbsp; {{this.currentUser[0].lastName}}
              </div>
              <a [routerLink]="['/ChangePassword']" class="relative" mat-ripple>
              <button mat-menu-item>
                <mat-icon >vpn_key</mat-icon>

               Change Password 
              </button>
            </a>
              <button mat-menu-item (click)="onLogout()">
                <mat-icon>logout</mat-icon>
                <a>                 
                  Log Out
                </a>
              </button>
              
               <!-- <div mat-menu-item class="head-menu grad-blue"> 
                Settings
              </div>  
               <button mat-menu-item>
                <mat-icon>settings</mat-icon>
                Setting
              </button>
              <button mat-menu-item>
                <mat-icon>account_box</mat-icon>
                Profile
              </button>
              <button mat-menu-item>
                <mat-icon>notifications_off</mat-icon>
                Disable notifications
              </button>
              <button mat-menu-item>
                <mat-icon>exit_to_app</mat-icon>
                Sign Out
              </button>  -->
            </mat-menu>
          </div>
        </div>
      </div>
  
    </mat-toolbar>
    <!-- <div class="horizontal-menu text-center"> -->
      <div class="horizontal-menu ">
      <nav>
        <ul class="main-h-list">
          <li *ngFor="let menuitem of menusLink" >
            <a style="align-items: center;" [routerLink]="['/'+menuitem.state]" *ngIf="menuitem.type === 'link'">
              <mat-icon>{{ menuitem.icon }}</mat-icon>
              <span >{{ menuitem.name | translate }}</span>
            </a>
            <a style="align-items: center;" [routerLink]="['/'+menuitem.state]" *ngIf="menuitem.type === 'campaignstrategy'">
              <mat-icon>{{ menuitem.icon }}</mat-icon>
              <span>{{ menuitem.name | translate }}</span>
            </a>
            <ul class="dropdown" *ngIf="menuitem.type === 'sub'">
              <li *ngFor="let childitem of menuitem.children">
                <a [routerLink]="['/'+childitem.state ]">{{ childitem.name | translate }}</a>
                <ul class="dropdown" *ngIf="childitem.type === 'super-sub'">
                  <li *ngFor="let subchild of childitem.subchildren">
                    <a [routerLink]="['/'+subchild.state ]">{{subchild.name | translate}}</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
    <div class="body-container">
      <router-outlet></router-outlet>
    </div>
    <mat-sidenav #end position="end" class="chat-panel" mode="over" opened="false">
      <mat-tab-group [selectedIndex]="1" mat-stretch-tabs>
        <mat-tab>
          <ng-template mat-tab-label>Today</ng-template>
          <div class="scroll">
            <mat-list class="pt-1 pb-1">
              <mat-list-item>
                <div fxLayout="column">
                  <h2 class="ma-0">{{ today | date:'EEEE' }}</h2>
                  <h6 class="mat-text-muted ma-0">
                    <span>{{ today | date:'dd' }}</span>&nbsp;<span>{{ today | date:'MMMM' }}</span>
                  </h6>
                </div>
              </mat-list-item>
            </mat-list>
            <mat-nav-list>
              <mat-divider></mat-divider>
              <h3 mat-subheader class="text-uppercase font-weight-bold">Projects Stats</h3>
              <mat-list-item>
                <p class="text-mat" mat-line>Project A</p>
                <mat-progress-bar mat-line mode="determinate" color="warn" value="20"></mat-progress-bar>
              </mat-list-item>
              <mat-list-item>
                <p class="text-mat" mat-line>Project B </p>
                <mat-progress-bar mat-line mode="determinate" color="accent" value="80"></mat-progress-bar>
              </mat-list-item>
              <mat-list-item>
                <p class="text-mat" mat-line>Project C</p>
                <mat-progress-bar mat-line mode="determinate" value="30"></mat-progress-bar>
              </mat-list-item>
              <mat-divider></mat-divider>
              <h3 mat-subheader class="text-uppercase font-weight-bold">Todo</h3>
              <mat-list-item>
                <a mat-line href="javascript:;">Consectetur adipisicing elit. Nemo omnis.</a>
                <p mat-line class="mat-text-muted text-mat">2:45PM</p>
              </mat-list-item>
              <mat-list-item>
                <a mat-line href="javascript:;"> Temporibus cumque cupiditate </a>
                <p mat-line class="mat-text-muted text-mat">3:20PM</p>
              </mat-list-item>
              <mat-list-item>
                <a mat-line href="javascript:;">Libero voluptatibus harum quis accusamus</a>
                <p mat-line class="mat-text-muted text-mat">16:00PM</p>
              </mat-list-item>
            </mat-nav-list>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>Notifications</ng-template>
          <div class="scroll">
            <mat-nav-list>
              <mat-list-item>
                <mat-icon mat-list-avatar class="mat-text-primary">people</mat-icon>
                <h4 mat-line>Social</h4>
                <p mat-line> 5 new members joined today</p>
              </mat-list-item>
              <mat-list-item>
                <mat-icon mat-list-avatar class="mat-text-warn">local_offer</mat-icon>
                <h4 mat-line>Promotions</h4>
                <p mat-line>Updated your email
                </p>
              </mat-list-item>
              <mat-list-item>
                <mat-icon mat-list-avatar class="mat-text-accent">info</mat-icon>
                <h4 mat-line>Updates</h4>
                <p mat-line> 15 new product added</p>
              </mat-list-item>
              <mat-list-item>
                <mat-icon mat-list-avatar class="mat-deep-purple">delete_sweep</mat-icon>
                <h4 mat-line> 2 order cancelled</h4>
                <span class="text-mat mat-text-muted" mat-line>{{ 1427207139000 | date: 'fullDate' }}</span>
              </mat-list-item>
              <mat-list-item>
                <mat-icon mat-list-avatar>check_circle</mat-icon>
                <h4 mat-line>System Scan completed</h4>
                <span class="text-mat mat-text-muted" mat-line>{{ 1427412725000 | date: 'fullDate' }}</span>
              </mat-list-item>
              <mat-list-item>
                <mat-icon mat-list-avatar>person_add</mat-icon>
                <h4 mat-line>4 Users connected</h4>
                <span class="text-mat mat-text-muted" mat-line>{{ 1428275520000 | date: 'fullDate' }}</span>
              </mat-list-item>
            </mat-nav-list>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-sidenav>
  </mat-sidenav-container>
  <!-- Demo Purposes Only -->
  <!-- <button mat-fab color="warn" class="mat-fab-bottom-right" (click)="showSettings = true">
    <mat-icon class="mat-24">settings</mat-icon>
  </button> -->
  <mat-card class="settings-panel" *ngIf="showSettings">
    <mat-toolbar color="warn">
      <span fxFlex>Options</span>
      <button mat-icon-button (click)="showSettings = false">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
    <mat-card-content class="demo-checkbox">
      <div class="sep-block">
        <div class="sep-block-header">
          <h6>Sidebar Image</h6>
        </div>

        <div class="bg-img clearfix">
          <div [ngClass]="{'border-active' : isBgActive('bg-1')}">
            <a (click)="onSelectSidebarImage('bg-1')">
              <img src="assets/images/bg-5.jpg" alt="">
            </a>
          </div>
          <div [ngClass]="{'border-active' : isBgActive('bg-2')}">
            <a (click)="onSelectSidebarImage('bg-2')">
              <img src="assets/images/bg-2.jpg" alt="">
            </a>
          </div>
          <div [ngClass]="{'border-active' : isBgActive('bg-3')}">
            <a (click)="onSelectSidebarImage('bg-3')">
              <img src="assets/images/bg-3.jpg" alt="">
            </a>
          </div>
          <div [ngClass]="{'border-active' : isBgActive('bg-4')}">
            <a (click)="onSelectSidebarImage('bg-4')">
              <img src="assets/images/bg-4.jpg" alt="">
            </a>
          </div>
        </div>
      </div>
      <div class="sep-block">
        <div>
          <mat-checkbox [(ngModel)]="sidebarBg"> <strong>Sidebar Image</strong></mat-checkbox>
        </div>
      </div>
      <div class="sep-block">
        <div class="color-sidebar clearfix">
          <div class="sep-block-header">
            <h6>Sidebar Color</h6>
          </div>

          <div class="mb-1" [ngClass]="{'border-active' : isSidebarActive('sidebar-default')}">
            <a href="javascript:void(0)" (click)="onSelectedSidebarColor('sidebar-default')"><i aria-hidden="true"
                class="color-default fa fa-circle fa-lg def"></i>
              Default</a>
          </div>
          <div class="mb-1" [ngClass]="{'border-active' : isSidebarActive('sidebar-green')}">
            <a href="javascript:void(0)" (click)="onSelectedSidebarColor('sidebar-green')"><i aria-hidden="true"
                class="color-green fa fa-circle fa-lg"></i>
              Green</a>
          </div>
          <div class="mb-1" [ngClass]="{'border-active' : isSidebarActive('sidebar-red')}">
            <a href="javascript:void(0)" (click)="onSelectedSidebarColor('sidebar-red')"><i aria-hidden="true"
                class="color-red fa fa-circle fa-lg"></i>
              Red</a>
          </div>
          <div class="mb-1" [ngClass]="{'border-active' : isSidebarActive('sidebar-purple')}">
            <a href="javascript:void(0)" (click)="onSelectedSidebarColor('sidebar-purple')"><i aria-hidden="true"
                class="color-purple fa fa-circle fa-lg"></i>
              Purple</a>
          </div>
          <div class="mb-1" [ngClass]="{'border-active' : isSidebarActive('sidebar-pink')}">
            <a href="javascript:void(0)" (click)="onSelectedSidebarColor('sidebar-pink')"><i aria-hidden="true"
                class="color-pink fa fa-circle fa-lg"></i>
              Pink</a>
          </div>
          <div class="mb-1" [ngClass]="{'border-active' : isSidebarActive('sidebar-blue')}">
            <a href="javascript:void(0)" (click)="onSelectedSidebarColor('sidebar-blue')"><i aria-hidden="true"
                class="color-blue fa fa-circle fa-lg"></i>
              Blue</a>
          </div>
          <div class="mb-1" [ngClass]="{'border-active' : isSidebarActive('sidebar-green-deep')}">
            <a href="javascript:void(0)" (click)="onSelectedSidebarColor('sidebar-green-deep')"><i aria-hidden="true"
                class="color-green-deep fa fa-circle fa-lg"></i>
              Green Deep</a>
          </div>
          <div class="mb-1" [ngClass]="{'border-active' : isSidebarActive('sidebar-brown')}">
            <a href="javascript:void(0)" (click)="onSelectedSidebarColor('sidebar-brown')"><i aria-hidden="true"
                class="color-brown fa fa-circle fa-lg"></i>
              Brown</a>
          </div>
          <div class="mb-1" [ngClass]="{'border-active' : isSidebarActive('sidebar-orange')}">
            <a href="javascript:void(0)" (click)="onSelectedSidebarColor('sidebar-orange')"><i aria-hidden="true"
                class="color-orange fa fa-circle fa-lg"></i>
              Orange</a>
          </div>
        </div>
      </div>
      <div class="sep-block">
        <div class="sep-block-header">
          <h6>Header Color</h6>
        </div>
        <div class="color-header clearfix">


          <div class="mb-1" [ngClass]="{'border-active' : isHeaderActive('header-default')}">
            <a href="javascript:void(0)" (click)="onSelectedHeaderColor('header-default')"><i aria-hidden="true"
                class="color-default fa fa-circle fa-lg def"></i>
              Default</a>
          </div>
          <div class="mb-1" [ngClass]="{'border-active' : isHeaderActive('header-green')}">
            <a href="javascript:void(0)" (click)="onSelectedHeaderColor('header-green')"><i aria-hidden="true"
                class="color-green fa fa-circle fa-lg"></i>
              Green</a>
          </div>
          <div class="mb-1" [ngClass]="{'border-active' : isHeaderActive('header-red')}">
            <a href="javascript:void(0)" (click)="onSelectedHeaderColor('header-red')"><i aria-hidden="true"
                class="color-red fa fa-circle fa-lg"></i>
              Red</a>
          </div>
          <div class="mb-1" [ngClass]="{'border-active' : isHeaderActive('header-purple')}">
            <a href="javascript:void(0)" (click)="onSelectedHeaderColor('header-purple')"><i aria-hidden="true"
                class="color-purple fa fa-circle fa-lg"></i>
              Purple</a>
          </div>
          <div class="mb-1" [ngClass]="{'border-active' : isHeaderActive('header-pink')}">
            <a href="javascript:void(0)" (click)="onSelectedHeaderColor('header-pink')"><i aria-hidden="true"
                class="color-pink fa fa-circle fa-lg"></i>
              Pink</a>
          </div>
          <div class="mb-1" [ngClass]="{'border-active' : isHeaderActive('header-blue')}">
            <a href="javascript:void(0)" (click)="onSelectedHeaderColor('header-blue')"><i aria-hidden="true"
                class="color-blue fa fa-circle fa-lg"></i>
              Blue</a>
          </div>
          <div class="mb-1" [ngClass]="{'border-active' : isHeaderActive('header-green-deep')}">
            <a href="javascript:void(0)" (click)="onSelectedHeaderColor('header-green-deep')"><i aria-hidden="true"
                class="color-green-deep fa fa-circle fa-lg"></i>
              Green Deep</a>
          </div>
          <div class="mb-1" [ngClass]="{'border-active' : isHeaderActive('header-brown')}">
            <a href="javascript:void(0)" (click)="onSelectedHeaderColor('header-brown')"><i aria-hidden="true"
                class="color-brown fa fa-circle fa-lg"></i>
              Brown</a>
          </div>
          <div class="mb-1" [ngClass]="{'border-active' : isHeaderActive('header-orange')}">
            <a href="javascript:void(0)" (click)="onSelectedHeaderColor('header-orange')"><i aria-hidden="true"
                class="color-orange fa fa-circle fa-lg"></i>
              Orange</a>
          </div>
        </div>
      </div>
      <div class="sep-block">
        <div class="sep-block-header">
          <h6>Layouts</h6>
        </div>
        <div class="pb-1">
          <mat-checkbox [(ngModel)]="horizontal" (change)="changeMenuLayout($event.checked)">Horizontal Menu
          </mat-checkbox>
        </div>
        <div class="pb-1">
          <mat-checkbox [(ngModel)]="collapseSidebar" (change)="compactSidebar = false">Collapsed Sidebar</mat-checkbox>
        </div>
        <div class="pb-1">
          <mat-checkbox [(ngModel)]="compactSidebar" (change)="collapseSidebar = false">Compact Sidebar</mat-checkbox>
        </div>
        <div class="pb-1">
          <mat-checkbox [(ngModel)]="boxed">Boxed Layout</mat-checkbox>
        </div>
        <div class="pb-1">
          <mat-checkbox [(ngModel)]="dark">Dark Mode</mat-checkbox>
        </div>
        <div class="pb-1">
          <mat-checkbox (change)="layoutDir = (layoutDir == 'rtl' ? 'ltr' : 'rtl')">RTL</mat-checkbox>
        </div>
      </div>
      <div class="sep-block">
        <div class="sep-block-header">
          <h6>Language</h6>
        </div>
        <div class="pb-3">
          <mat-form-field>
            <mat-select class="m0-1" [(ngModel)]="currentLang" #langSelect="ngModel"
              (ngModelChange)="translate.use(currentLang)">
              <mat-option *ngFor="let lang of translate.getLangs()" [value]="lang">{{ lang }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <!-- /Demo Purposes Only -->
</div>