import { RestDataSource } from "app/model/rest.datasource";
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from "@angular/platform-browser";
import { Router } from "@angular/router";

@Component({
  selector: "App-BannerAdCreativePerformance",
  templateUrl: "./banneradcreativeperformance.component.html",
  styleUrls: ["./banneradcreativeperformance.component.scss"],
})
export class BannerAdCreativePerformance implements OnInit {
  data: any;
  LoggedInUserId;

  pixelVerificationdone;
  conversionVerificationdone;
  showconversions: boolean;
  showvtc: boolean;
  ActualData: any[];

  totalImpressions = 0;
  totalConversions = 0;
  totalClicks = 0;
  totalVtc = 0;
  CPC = 0;
  CPM = 0;
  totalrevenue = 0;
  finalresult = [];
  showconversionsRevenue: boolean;
  renameConversion: boolean;
  showSpinner: boolean = true;
  rowSize: any;
  sumOfConversions = 0;
  sumOfRevenue = 0;
  sumOfVTC = 0;
  sharedRptRequest: boolean = false;

  @ViewChild("iframe") iframe: ElementRef;

  bannerAD: any = [];
  campaignname;
  uploadType: any;

  constructor(
    private restDataSource: RestDataSource,
    private fb: FormBuilder,
    public sanitizer: DomSanitizer,
    private router: Router
  ) {}
  @Input() lineitemidFromParent: any;
  @Input() dropdownList_flightsFromparent: any;
  @Output() BannerAdPerformanceUpdatedOn: EventEmitter<any> =
    new EventEmitter();

  update_BannerAdCreativePerformance(
    LineItemFromParent,
    FlightData,
    userId,
    campaignName,
    data1
  ): void {
    this.LoggedInUserId = userId;
    this.campaignname = campaignName;
    this.sharedRptRequest = data1;
    this.getClientDetails(LineItemFromParent, FlightData);
  }

  ngOnInit() {
    this.data = [];
    this.LoggedInUserId = this.LoggedInUserId;
    this.campaignname = this.campaignname;
    this.showSpinner = false;
  }

  viewAdClicked() {
    this.router.navigateByUrl("/creative");
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getClientDetails(id, dropdownList_flightsFromparent) {
    this.showSpinner = true;
    this.restDataSource.getBanneradCreativePerformance(id, dropdownList_flightsFromparent, this.LoggedInUserId).subscribe((data) => {
        this.campaignname = this.campaignname;
        var conversionflag;
        this.data = data;

        if (this.data != undefined && this.data.length > 0) {
          this.showSpinner = false;
        } else {
          this.showSpinner = false;
        }

        if (this.data != undefined && this.data.length > 0) {
            var arr = [
                    {
                        name: 'John',
                        contributions: 2
                    }, {
                        name: 'Mary',
                        contributions: 4
                    }, {
                        name: 'John',
                        contributions: 1
                    }, {
                        name: 'Mary',
                        contributions: 1
                    }
                ],
                result = [];
            this.finalresult = [];
            var temp = [];
            var tempShowNo = [];
            var res = [];

            res= this.data;

            res.forEach(function (a) {
                if (!this[a.hexcode]) {
                    this[a.hexcode] = {
                        hexcode: a.hexcode,
                        totalImpressions: 0,
                        totalClicks: 0,
                        totalConversions: 0,
                        totalrevenue: 0,
                        budget: 0,
                        imageType: '',
                        Description: '',
                        heightSize: 0,
                        widthSize: 0,
                        liveDate: '',
                        uploadType: '',
                        conversionFlag: '',
                        add_size: ''
                    };
                    result.push(this[a.hexcode]);
                }

                this[a.hexcode].totalImpressions = a.impressions;
                this[a.hexcode].totalClicks = a.clicks;
                this[a.hexcode].totalConversions = a.totalConversions;
                this[a.hexcode].totalrevenue = a.totalRevenue;
                this[a.hexcode].budget = a.budget;
                this[a.hexcode].imageType = a.imageType;
                this[a.hexcode].Description = a.Description;
                this[a.hexcode].heightSize = a.heightSize;
                this[a.hexcode].widthSize = a.widthSize;
                this[a.hexcode].liveDate = a.liveDate;
                this[a.hexcode].uploadType = a.uploadType;
                this[a.hexcode].conversionFlag = a.ConversionFlag;
                this[a.hexcode].totalVtc = a.VTC;
                this[a.hexcode].add_size = a.add_size;
            }, Object.create(null));

            this.finalresult = [];
            this.sumOfConversions = 0;
            this.sumOfRevenue = 0;
            
            for (var i = 0; i < result.length; i++) {
                const m1: any = {};
                m1.totalImpressions = result[i].totalImpressions;
                m1.totalClicks = result[i].totalClicks;
                m1.totalConversions = result[i].totalConversions;
                m1.totalrevenue = result[i].totalrevenue;
                m1.conversionFlag = result[i].conversionFlag;
                m1.totalVtc = result[i].totalVtc;
                if (result[i].totalImpressions == 0) {
                    m1.CPM = 0;
                } else {
                    m1.CPM = (result[i].budget * 1000) / result[i].totalImpressions;
                }

                if (result[i].totalClicks == 0) {
                    m1.CPC = 0;
                } else {
                    m1.CPC = (result[i].budget) / result[i].totalClicks;
                }
                if (result[i].widthSize == '300') {
                    if (result[i].heightSize == '250') {
                        if (result[i].imageType == 'image') {
                            m1.uploadType = result[i].uploadType;
                        }
                        if (result[i].imageType == 'HTML') {
                            m1.uploadType = this.sanitizer.bypassSecurityTrustResourceUrl(result[i].uploadType);
                        }
                        m1.showAd = 'yes';
                    }
                    else{
                        m1.showAd = 'no';
                    }
                } else {
                    if (result[i].imageType == 'image') {
                        m1.uploadType = result[i].uploadType;
                    }
                    if (result[i].imageType == 'HTML') {
                        m1.uploadType = this.sanitizer.bypassSecurityTrustResourceUrl(result[i].uploadType);
                    }
                    m1.showAd = 'no';
                } 
                m1.widthSize = result[i].widthSize;
                m1.heightSize = result[i].heightSize;
                m1.imageType = result[i].imageType;
                m1.liveDate = result[i].liveDate;
                m1.Description = result[i].Description;
                m1.add_size = result[i].add_size;

                this.finalresult.push(m1);

                this.sumOfConversions += result[i].totalConversions;
                this.sumOfRevenue += result[i].totalrevenue;
                this.sumOfVTC += result[i].totalVtc;
            }
        } else {
            this.finalresult = [];
            conversionflag = "";
            this.sumOfConversions = 0;
            this.sumOfRevenue = 0;
            this.sumOfVTC = 0;
        }
    });
}


}
