import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import Swal from 'ngx-angular8-sweetalert2'

import 'ngx-angular8-sweetalert2/src/sweetalert2.scss'

import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { RestDataSource } from 'app/model/rest.datasource';
import { DataService } from 'app/Service/data.service';
import { couldStartTrivia } from 'typescript';
import { Router } from '@angular/router';

@Component({
  selector: 'app-accountSuspended',
  templateUrl: './account-suspended.component.html',
  styleUrls: ['./account-suspended.component.scss']
})
export class AccountSuspendedComponent implements OnInit {
  CampaignForm: FormGroup;
  BCILineItems: any = {};
  userId: string;
  today = new Date();
  model: any = {};
  lineItemName: any = '';
  invoiceData: any = [];
  constructor(private fb: FormBuilder, private restDataSource: RestDataSource, private router: Router) {

  }
  ngOnInit() {
    var userId = localStorage.getItem('UserId');
    var aryID: any = [];
    this.restDataSource.getPaymentStatusDetails(userId).subscribe(res => {

      // if (res.response.length > 0) {
      //   this.invoiceData = res.response;
      //    var checkAry = this.invoiceData.filter(item => item.paymentStatus == 'CURRENT');
        
         
      //    if(checkAry.length == 0 ){
        
      //   res.response.forEach(element => {
      //     if (element.invoicingLink != '' && element.invoicingLink != null)
      //       aryID.push(element.LineItemName);
      //   });
      //   this.lineItemName = aryID.toString();
      // }
        
      //   if (aryID.length == 0) {
      //     this.checkTermAndCondition();
      //   }
      // } else {
      //   this.checkTermAndCondition();
      // }

      if (res.response.length > 0) {
        this.invoiceData = res.response;
         var checkAry = this.invoiceData.filter(item => item.paymentStatus == 'DELINQUENT');
         if(checkAry.length > 0 ){
        // res.response.forEach(element => {
        //   if (element.invoicingLink != '' && element.invoicingLink != null)
        //     aryID.push(element.LineItemName);
        // });
        checkAry.forEach(element => {
          if (element.invoicingLink != '' && element.invoicingLink != null)
            aryID.push(element.LineItemName);
        });
        this.lineItemName = aryID.toString();
      }
        if (aryID.length == 0) {
          this.checkTermAndCondition();
        }
        else{
          localStorage.setItem('showMenu', 'false');
        }
      } else {
        this.checkTermAndCondition();
      }

    })
  }
  checkTermAndCondition() {
    var userId = localStorage.getItem('UserId');
    this.restDataSource.getUserTermandConditionCheck(userId).subscribe(resp => {
      
      this.restDataSource.getTermandConditionAdmin(userId, 'GetTermsandCondition').subscribe(data => {
        if (resp.response.length == 0) {
          localStorage.setItem('showMenu', 'false');
          this.router.navigateByUrl('/TermAndCondition');
        }
        else if (resp.response[0].exemptAllTerm == true || resp.response[0].exemptAllTerm == 1) {
          localStorage.setItem('showMenu', 'true');
          this.router.navigateByUrl('/campaignperformance');
        }
        else {
          if (resp.response.length == 0) {
            localStorage.setItem('showMenu', 'false');
            this.router.navigateByUrl('/TermAndCondition');
          }
          else if ((data.response[0].uploadedDate > resp.response[0].acceptpaymentTerm || resp.response[0].acceptpaymentTerm == null) || resp.response[0].acceptpaymentTerm == null || resp.response[0].acceptserviceTerm == null
            || (data.response[0].serviceUploadedDate > resp.response[0].acceptserviceTerm)) {
            localStorage.setItem('showMenu', 'false');
            this.router.navigateByUrl('/TermAndCondition');
          }
          else {
            localStorage.setItem('showMenu', 'true');
            this.router.navigateByUrl('/campaignperformance');
          }
        }
      })

    })
  }

}