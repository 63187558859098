import { Component, OnInit,ViewEncapsulation }      from '@angular/core';

@Component({
    selector: 'ms-error-404',
  	templateUrl:'./error-404-component.html',
 	  styleUrls: ['./error-404-component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ErrorOneComponent implements OnInit {

	constructor() {}

   	ngOnInit() {

   	}

 
}


