import { RestDataSource } from "app/model/rest.datasource";
// import { single, multi } from "./../chartlib/charts.data";
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "App-BannerAdCreativePerformanceByDevice",
  templateUrl: "./banneradcreativeperformancebydevice.component.html",
  styleUrls: ["./banneradcreativeperformancebydevice.component.scss"],
})
export class BannerAdCreativePerformanceByDevice implements OnInit {
  data: any;

  pixelVerificationdone;
  conversionVerificationdone;
  showconversions: boolean;
  showvtc: boolean;
  LoggedInUserId;
  showUpsellImage: boolean;
  showSpinner: boolean = true;

  DeskTopImpressions = 0;
  DeskTopTotalClicks = 0;
  DeskTopTotalConversions = 0;
  DeskTopVTC = 0;
  DeskTopMediaType = "";

  TabletImpressions = 0;
  TabletTotalClicks = 0;
  TabletTotalConversions = 0;
  TabletVTC = 0;
  TabletMediaType = "";

  MobileImpressions = 0;
  MobileTotalClicks = 0;
  MobileTotalConversions = 0;
  MobileVTC = 0;
  MobileMediaType = "";

  OthersImpressions = 0;
  OthersTotalClicks = 0;
  OthersTotalConversions = 0;
  OthersVTC = 0;
  OthersMediaType = "";

  showDesktopUpsell = false;
  showTabletUpsell = false;
  showMobileUpsell = false;
  showOthersUpsell = false;
  sumOfConversions = 0;
  sumofVTC = 0;

  constructor(
    private restDataSource: RestDataSource,
    private fb: FormBuilder,
    private el: ElementRef
  ) {}
  @Input() lineitemidFromParent: any;
  @Input() dropdownList_flightsFromparent: any;
  @Output() BannerAdPerformanceByDeviceUpdatedOn: EventEmitter<any> =
    new EventEmitter();

  update_BannerAdCreativePerformanceByDevice(data1, data2, data3, data4): void {
    this.LoggedInUserId = data3;
    this.getBannerAdPerformanceByDeviceData(data1, data2);
  }

  ngOnInit() {
    this.data = [];
    this.LoggedInUserId = this.LoggedInUserId;
    this.showSpinner = false;
  }

  getBannerAdPerformanceByDeviceData(
    lineitemidFromParent,
    dropdownList_flightsFromparent
  ) {
    this.showSpinner = true;
    this.restDataSource
      .GetBannerAdPerformanceByDeviceData(
        lineitemidFromParent,
        dropdownList_flightsFromparent,
        this.LoggedInUserId
      )
      .subscribe((data) => {
        this.DeskTopImpressions = 0;
        this.DeskTopTotalClicks = 0;
        this.DeskTopTotalConversions = 0;
        this.DeskTopVTC = 0;
        this.DeskTopMediaType = "";

        this.TabletImpressions = 0;
        this.TabletTotalClicks = 0;
        this.TabletTotalConversions = 0;
        this.TabletVTC = 0;
        this.TabletMediaType = "";

        this.MobileImpressions = 0;
        this.MobileTotalClicks = 0;
        this.MobileTotalConversions = 0;
        this.MobileVTC = 0;
        this.MobileMediaType = "";

        this.OthersImpressions = 0;
        this.OthersTotalClicks = 0;
        this.OthersTotalConversions = 0;
        this.OthersVTC = 0;
        this.OthersMediaType = "";
        this.sumOfConversions = 0;
        this.sumofVTC = 0;

        if (data == undefined) {
          this.data = [];
          this.showSpinner = true;
        } else {
          this.data = data;
          let sortedCompany = this.data.sort((a, b) =>
            a.MediaType < b.MediaType ? -1 : 1
          );
          this.data = sortedCompany;
          var date: any;

          if (
            this.data.length == 0 ||
            this.data[0].updatedOn == undefined ||
            this.data[0].updatedOn == "" ||
            this.data[0].updatedOn == null
          ) {
            date = "";
          } else {
            date = this.data[0].updatedOn;
          }
          this.BannerAdPerformanceByDeviceUpdatedOn.emit(date);

          this.showDesktopUpsell = true;
          this.showTabletUpsell = true;
          this.showMobileUpsell = true;
          this.showOthersUpsell = true;

          this.sumOfConversions = 0;
          this.sumofVTC = 0;
          for (let i = 0; i < this.data.length; i++) {
            if (this.data[i].MediaType == "Desktop / Laptop") {
              this.DeskTopImpressions = this.data[i].totalimpswon;
              this.DeskTopTotalConversions = this.data[i].totalConversions;
              this.DeskTopMediaType = this.data[i].mediatype;
              this.DeskTopTotalClicks = this.data[i].totalclicks;
              this.DeskTopVTC = this.data[i].VTC;
              this.showDesktopUpsell = false;

              this.sumOfConversions += this.data[i].totalConversions;
              this.sumofVTC += this.data[i].VTC;
            }
            if (this.data[i].MediaType == "Tablet") {
              this.TabletImpressions = this.data[i].totalimpswon;
              this.TabletTotalConversions = this.data[i].totalConversions;
              this.TabletMediaType = this.data[i].mediatype;
              this.TabletTotalClicks = this.data[i].totalclicks;
              this.TabletVTC = this.data[i].VTC;
              this.showTabletUpsell = false;

              this.sumOfConversions += this.data[i].totalConversions;
              this.sumofVTC += this.data[i].VTC;
            }
            if (this.data[i].MediaType == "Mobile") {
              this.MobileImpressions = this.data[i].totalimpswon;
              this.MobileTotalConversions = this.data[i].totalConversions;
              this.MobileMediaType = this.data[i].mediatype;
              this.MobileTotalClicks = this.data[i].totalclicks;
              this.MobileVTC = this.data[i].VTC;
              this.showMobileUpsell = false;

              this.sumOfConversions += this.data[i].totalConversions;
              this.sumofVTC += this.data[i].VTC;
            }
            if (this.data[i].MediaType == "Other") {
              this.OthersImpressions = this.data[i].totalimpswon;
              this.OthersTotalConversions = this.data[i].totalConversions;
              this.OthersMediaType = this.data[i].mediatype;
              this.OthersTotalClicks = this.data[i].totalclicks;
              this.OthersVTC = this.data[i].VTC;
              this.showOthersUpsell = false;

              this.sumOfConversions += this.data[i].totalConversions;
              this.sumofVTC += this.data[i].VTC;
            }
          }
        }

        var conversionflag;
        if (this.data.length > 0) {
          conversionflag = this.data[0].conversionFlag;
          this.showUpsellImage = false;
          this.showSpinner = false;
        } else {
          conversionflag = "";
          this.showUpsellImage = true;
          this.showSpinner = false;
        }

      });
  }
}
