<div>
  <div
    style="padding-top: 15px; padding-right: 0.5%"
    class="d-flex flex-row row"
  >
    <div style="width: 100%" *ngIf="showmap">
      <agm-map
        [fitBounds]="bounds"
        [mapTypeId]="'roadmap'"
        [disableDefaultUI]="false"
      >
        <agm-polygon
          [paths]="dp.Polygon"
          fillOpacity=".25"
          fillColor="#3588cc"
          strokeColor="#3588cc"
          *ngFor="let dp of result; let i = index"
        >
          <!-- <agm-info-window
            [latitude]="dp.Polygon[0][0].lat"
            [longitude]="dp.Polygon[0][0].lng"
            #infoWindow
          >
            <div>
              <strong>Zip Code : test</strong>
            </div>
          </agm-info-window> -->
        </agm-polygon>
        <agm-marker
          [agmFitBounds]="fitBounds"
          [visible]="false"
          *ngFor="let zoom of setZoom; let i = index"
          [latitude]="zoom.lat"
          [longitude]="zoom.lng"
        >
          <!-- 29.63140,-98.41325 -->
        </agm-marker>
        <agm-marker
          #marker
          [latitude]="stadiumlatitude"
          [longitude]="stadiumlongitude"
        >
          <agm-overlay
            [latitude]="stadiumlatitude"
            [longitude]="stadiumlongitude"
          >
            <i class="fas fa-map-marker"></i>
          </agm-overlay>
        </agm-marker>
      </agm-map>
    </div>
  </div>
</div>
