import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { HorizontalMenuItems } from '../../shared/menu-items/horizontal-menu-items';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateService } from '@ngx-translate/core';
import PerfectScrollbar from 'perfect-scrollbar';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective
} from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-layout',
  templateUrl: './admin-layout.component.html'
})
export class AdminLayoutComponent implements OnInit, OnDestroy {

  private _router: Subscription;

  today: number = Date.now();
  url: string;
  showSettings = false;
  dark: boolean;
  boxed: boolean;
  collapseSidebar: boolean;
  compactSidebar: boolean;
  horizontal: boolean = false;
  sidebarBg: boolean = true;
  currentLang = 'en';
  layoutDir = 'ltr';
  searchFocus: boolean = false;

  menuLayout: any = 'top-menu';
  selectedSidebarImage: any = 'bg-1';
  selectedSidebarColor: any = 'sidebar-default';
  selectedHeaderColor: any = 'header-default';
  collapsedClass: any = 'side-panel-opened';

  @ViewChild('sidemenu', { static: true }) sidemenu;
  public config: PerfectScrollbarConfigInterface = {};
  currentUser: any = [];
  menusLink: import("../../shared/menu-items/horizontal-menu-items").Menu[];


  constructor(private router: Router, public menuItems: MenuItems, public horizontalMenuItems: HorizontalMenuItems, public translate: TranslateService) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));


    const elemSidebar = <HTMLElement>document.querySelector('.sidebar-container ');
    var hideMenu = localStorage.getItem('showMenu');
    localStorage.removeItem('showMenu');
    
    if (hideMenu == 'true' || hideMenu == undefined || hideMenu == null ) {
      this.menusLink = this.horizontalMenuItems.getAll();
    }
    this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.url = event.url;
      if (this.isOver()) {
        this.sidemenu.close();
      }

      if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac() && !this.compactSidebar && this.layoutDir != 'rtl') {
        // Ps.update(elemContent);
      }
    });
  }

  @HostListener('click', ['$event'])
  onClick(e: any) {
    const elemSidebar = <HTMLElement>document.querySelector('.sidebar-container ');

  }

  ngOnDestroy() {
    this._router.unsubscribe();
  }

  isOver(): boolean {
    if (this.url === '/apps/messages' || this.url === '/apps/calendar' || this.url === '/apps/media' || this.url === '/maps/leaflet') {
      return true;
    } else {
      return window.matchMedia(`(max-width: 960px)`).matches;
    }
  }

  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }

  menuMouseOver(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && this.collapseSidebar) {
      this.sidemenu.mode = 'over';
    }
  }

  menuMouseOut(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && this.collapseSidebar) {
      this.sidemenu.mode = 'side';
    }
  }

  menuToggleFunc() {
    this.sidemenu.toggle();

    if (this.collapsedClass == 'side-panel-opened') {
      this.collapsedClass = 'side-panel-closed';
    }
    else {
      this.collapsedClass = 'side-panel-opened';
    }
  }

  changeMenuLayout(value) {

    if (value) {
      this.menuLayout = 'top-menu';
    }
    else {
      this.menuLayout = 'vertical-menu';
      this.menuToggleFunc();
    }
  }

  onSelectSidebarImage(selectedClass, event) {
    this.selectedSidebarImage = selectedClass;
  }

  onSelectedSidebarColor(selectedClass) {
    this.selectedSidebarColor = selectedClass;
  }

  onSelectedHeaderColor(selectedClass) {
    this.selectedHeaderColor = selectedClass;
  }

  isBgActive(value) {
    if (value == this.selectedSidebarImage) {
      return true;
    }
    else {
      return false;
    }
  }

  isSidebarActive(value) {
    if (value == this.selectedSidebarColor) {
      return true;
    }
    else {
      return false;
    }
  }

  isHeaderActive(value) {
    if (value == this.selectedHeaderColor) {
      return true;
    }
    else {
      return false;
    }
  }

  addMenuItem(): void {

  }

  onLogout() {
    localStorage.clear();
    window.location.href = '/authentication/login';



  }
}
