<!-- image start-->
<div *ngIf="showSpinner">
  <!-- <img
    alt=""
    pgRetina
    src1x="assets/img/Native Reporting/Native Reporting Mockups V4_bannercreative_loading.jpg"
    src2x="assets/img/Native Reporting/Native Reporting Mockups V4_bannercreative_loading.jpg"
    src="assets/img/Native Reporting/Native Reporting Mockups V4_bannercreative_loading.jpg"
  /> -->
  <div class="pre-mapLoader" [class.hideOnLoad]="isTrue">
    <div class="sk-cube-mapgrid">
      <div class="sk-cube map1"></div>
      <div class="sk-cube map2"></div>
      <div class="sk-cube map3"></div>
      <div class="sk-cube map4"></div>
      <div class="sk-cube map5"></div>
      <div class="sk-cube map6"></div>
      <div class="sk-cube map7"></div>
      <div class="sk-cube map8"></div>
      <div class="sk-cube map9"></div>
    </div>
  </div>
</div>
<!-- image end -->

<!-- <div
  class="row"
  *ngFor="let row of finalresult; index as i"
  style="margin-left: 15px; margin-bottom: 5px"
>
  <div class="col-md-5 col-lg-4 col-xs-6" *ngIf="!showSpinner">
    <img
      alt=""
      pgRetina="pgRetina"
      [src]="row.uploadType"
      style="border: 1px solid black"
      [height]="252"
      [width]="302"
    />
  </div>
  &nbsp;&nbsp;&nbsp;
  <div
    class="col-md-9 col-lg-8 col-xs-9"
    *ngIf="!showSpinner"
    style="margin-left: 0px"
  >
    <h6 style="color: #092e56">CAMPAIGN CREATED ON:</h6>
    <br />
    <div class="row" style="margin-top: 10px">
      <div class="col-md-4">
        <div class="valuestylechangesmain">
          <img
            alt=""
            pgRetina
            src1x="assets/img/Native Reporting/Impressions_Icon.png"
            src2x="assets/img/Native Reporting/Impressions_Icon.png"
            src="assets/img/Native Reporting/Impressions_Icon.png"
            style="height: 35px !important; width: 35px !important"
          />
          &nbsp;&nbsp;&nbsp;TOTAL IMPRESSIONS
        </div>
        <div *ngIf="row.length >0 && row.totalImpressions != null && row.totalImpressions !='' && row.totalImpressions != undefined" class="valuestylechanges">
          {{ row.totalImpressions | number : "1.0" : "en-US" }}
        </div>
        <div *ngIf="row.length <=0 || row.totalImpressions == null || row.totalImpressions =='' || row.totalImpressions == undefined" class="valuestylechanges">
          0
        </div>
      </div>

      <div class="col-md-4">
        <div class="valuestylechangesmain">
          <img
            alt=""
            pgRetina
            src1x="assets/img/Native Reporting/Clicks_Icon.png"
            src2x="assets/img/Native Reporting/Clicks_Icon.png"
            src="assets/img/Native Reporting/Clicks_Icon.png"
            style="height: 35px !important; width: 35px !important"
          />
          &nbsp;TOTAL CLICKS
        </div>
        <div *ngIf="row.length >0 && row.totalClicks != null || row.totalClicks !='' || row.totalClicks != undefined" class="valuestylechanges">
          {{ row.totalClicks }}
        </div>
        <div *ngIf="row.length <=0 || row.totalClicks == null || row.totalClicks =='' || row.totalClicks == undefined" class="valuestylechanges">
          0
        </div>
      </div>
    </div>
  </div>
</div> -->


<div class="row" *ngFor="let row of this.finalresult; index as i" style="margin-left: 15px;margin-bottom: 5px;">
  <div class="col-md-3 col-sm-12 col-lg-3 col-xs-12" *ngIf="!showSpinner">
        <iframe *ngIf="row.showAd == 'yes' && row.imageType == 'HTML'" [height]="252" style.width="auto" [src]="row.uploadType" scrolling="no" sandbox="allow-same-origin allow-scripts" frameborder="0" allow=" encrypted-media" allowfullscreen="allowfullscreen" safe></iframe>
        <img *ngIf="row.showAd == 'no' && row.imageType == 'HTML'" alt="" pgRetina="pgRetina" src="assets/img/Native Reporting/Placeholder.png" style="border: 1px solid black" [height]="252" [width]="302"/>
        <img *ngIf="row.showAd == 'yes' && row.imageType == 'image'" alt="" pgRetina="pgRetina" [src]="row.uploadType" style="border: 1px solid black" [height]="252" [width]="302"/>
        <img *ngIf="row.showAd == 'no' && row.imageType == 'image'" alt="" pgRetina="pgRetina" src="assets/img/Native Reporting/Placeholder.png" style="border: 1px solid black" [height]="252" [width]="302"/> 
  </div>
   &nbsp;&nbsp;&nbsp;
  <div class="col-md-9 col-sm-12 col-lg-9 col-xs-12" *ngIf="!showSpinner">
      <div class="row" *ngIf="row.liveDate == null || row.liveDate == '' || row.liveDate == undefined">
        <h6 style="color: #092e56">CAMPAIGN CREATED ON:   09/01/2023</h6>
        &nbsp; &nbsp;
      </div>
      <div  *ngIf="row.liveDate != null && row.liveDate != '' && row.liveDate != undefined" class="row">
        <h6 style="color: #092e56">CAMPAIGN CREATED ON:  {{ row.liveDate | date: "MM/dd/yyyy" }}</h6>
        &nbsp; &nbsp;
      </div>
      <!-- <div class="row" style="margin-top: 10px;">
          <div class="col-md-4"> -->
            <div class="valuestylechangesmain">
              <img
                alt=""
                pgRetina
                src1x="assets/img/Native Reporting/Impressions_Icon.png"
                src2x="assets/img/Native Reporting/Impressions_Icon.png"
                src="assets/img/Native Reporting/Impressions_Icon.png"
                style="height: 35px !important; width: 35px !important"
              />
              &nbsp;&nbsp;&nbsp;TOTAL DIGITAL IMPRESSIONS
            </div>
              <div class="valuestylechanges">{{row.totalImpressions | number:'1.0':'en-US'}}</div>
          <!-- </div>
      </div> -->
  </div>

</div>

<!-- if there is no data -->
<div class="row" *ngIf="this.finalresult.length <=0" style="margin-left: 15px; margin-bottom: 5px">
  <div class="col-md-3 col-sm-12 col-lg-3 col-xs-12"  *ngIf="!showSpinner">
    <img  alt="" pgRetina="pgRetina" src="assets/img/CampusBox/Campus Box placeholder image.svg" style="border: 1px solid black" [height]="252" [width]="302"/>
  </div>
  &nbsp;&nbsp;&nbsp;
  <div  class="col-md-9 col-sm-12 col-lg-9 col-xs-12" *ngIf="!showSpinner">
    <h6 style="color: #092e56">CAMPAIGN CREATED ON:</h6>
    <br />
    <!-- <div class="row" style="margin-top: 10px"> -->
      <!-- <div class="col-md-6"> -->
        <div class="valuestylechangesmain">
          <img
            alt=""
            pgRetina
            src1x="assets/img/Native Reporting/Impressions_Icon.png"
            src2x="assets/img/Native Reporting/Impressions_Icon.png"
            src="assets/img/Native Reporting/Impressions_Icon.png"
            style="height: 35px !important; width: 35px !important"
          />
          &nbsp;&nbsp;&nbsp;TOTAL DIGITAL IMPRESSIONS
        </div>
        <div  class="valuestylechanges">
          0
        </div>
      <!-- </div> -->

      <!-- <div class="col-md-4">
        <div class="valuestylechangesmain">
          <img
            alt=""
            pgRetina
            src1x="assets/img/Native Reporting/Clicks_Icon.png"
            src2x="assets/img/Native Reporting/Clicks_Icon.png"
            src="assets/img/Native Reporting/Clicks_Icon.png"
            style="height: 35px !important; width: 35px !important"
          />
          &nbsp;TOTAL CLICKS
        </div>
        <div  class="valuestylechanges">
          0
        </div>
      </div> -->
    <!-- </div> -->
  </div>
</div>


