import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {

  private messageSource = new BehaviorSubject('default idleTriggerMessage');
  currentMessage = this.messageSource.asObservable();

  constructor() { }
 
  changeMessage(idleTriggerMessage: string) {
    this.messageSource.next(idleTriggerMessage);
  }

  
  DashboardFlag:string='';
  brandId:string;
  brandName:string='';
}
