<div *ngIf="showSpinner">
  <!-- <img
    alt=""
    pgRetina
    src1x="assets/img/Native Reporting/Native Reporting Mockups V4_domains_loading.jpg"
    src2x="assets/img/Native Reporting/Native Reporting Mockups V4_domains_loading.jpg"
    src="assets/img/Native Reporting/Native Reporting Mockups V4_domains_loading.jpg"
  /> -->
  <div class="pre-mapLoader" [class.hideOnLoad]="isTrue">
    <div class="sk-cube-mapgrid">
      <div class="sk-cube map1"></div>
      <div class="sk-cube map2"></div>
      <div class="sk-cube map3"></div>
      <div class="sk-cube map4"></div>
      <div class="sk-cube map5"></div>
      <div class="sk-cube map6"></div>
      <div class="sk-cube map7"></div>
      <div class="sk-cube map8"></div>
      <div class="sk-cube map9"></div>
    </div>
  </div>
</div>

<div class="row mat-card" style="padding-top: 10px" *ngIf="!showSpinner">
  <div class="col-md-12 ">
    <table style="width: 100% !important">
      <thead>
        <tr>
          <th class="text_allign">
            <span class="allign-center"> DOMAIN </span>
            <div class="tooltip1">
              <i
                class="fa fa-info-circle"
                style="font-size: 15px; color: #f8ecec"
              ></i>
              <span class="tooltiptext tooltipposition"
                >The web addresses where your ad(s) were placed. Table reports top ten domains</span>
            </div>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div style="float: right">
              <span (click)="SortChannel('desc')" *ngIf="AscChannel == true">
                <i class="fa fa-sort-down"></i>
              </span>
              <span (click)="SortChannel('asc')" *ngIf="DescChannel == true">
                <i class="fa fa-sort-up"></i>
              </span>
            </div>
          </th>
          <th class="text_allign">
            <span class="allign-center">DIGITAL IMPRESSIONS </span>
            <div class="tooltip1">
              <i
                class="fa fa-info-circle"
                style="font-size: 15px; color: #f8ecec"
              ></i>
              <span class="tooltiptext tooltippositionImpressions"
                >Impressions are the number of times your ad was loaded onto the
                web page, within your target geography.</span
              >
            </div>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div style="float: right">
              <span
                (click)="SortImpressions('desc')"
                *ngIf="AscImpressions == true"
              >
                <i class="fa fa-sort-down"></i>
              </span>
              <span
                (click)="SortImpressions('asc')"
                *ngIf="DescImpressions == true"
              >
                <i class="fa fa-sort-up"></i>
              </span>
            </div>
          </th>
          <!-- <th class="text_allign">
            <span class="allign-center"> CLICKS </span>
            <div class="tooltip1">
              <i
                class="fa fa-info-circle"
                style="font-size: 15px; color: #f8ecec"
              ></i>
              <span class="tooltiptext tooltippositionCLicks"
                >The number of times someone clicked your ad and was taken to
                your website. If your ad received 50 clicks, for example, at
                least 50 people were redirected to your website.</span
              >
            </div>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div style="float: right">
              <span (click)="SortClicks('desc')" *ngIf="AscClicks == true">
                <i class="fa fa-sort-down"></i>
              </span>
              <span (click)="SortClicks('asc')" *ngIf="DescClicks == true">
                <i class="fa fa-sort-up"></i>
              </span>
            </div>
          </th> -->
          <th class="text_allign" *ngIf="this.showconversions == true">
            <span class="allign-center"> CONVERSIONS </span>
            <div class="tooltip1">
              <i
                class="fa fa-info-circle"
                style="font-size: 15px; color: #f8ecec"
              ></i>
              <span class="tooltiptext tooltippositionConversions"
                >The number of times someone exposed to your ad from this
                website completed a trackable activity on your website. Due to
                privacy changes, only an estimated 40-60% of all online events
                are trackable</span
              >
            </div>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div style="float: right">
              <span
                (click)="SortConversions('desc')"
                *ngIf="AscConversions == true"
              >
                <i class="fa fa-sort-down"></i>
              </span>
              <span
                (click)="SortConversions('asc')"
                *ngIf="DescConversions == true"
              >
                <i class="fa fa-sort-up"></i>
              </span>
            </div>
          </th>
          <th class="text_allign" *ngIf="this.showvtc == true">
            <span class="allign-center"> INDIRECT WEBSITE TRAFFIC </span>
            <div class="tooltip1">
              <i
                class="fa fa-info-circle"
                style="font-size: 15px; color: #f8ecec"
              ></i>
              <span class="tooltiptext tooltippositionVtc"
                >The number of times someone exposed to your ad on this website
                later visited your website</span
              >
            </div>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div style="float: right">
              <span (click)="SortVtc('desc')" *ngIf="AscVtc == true">
                <i class="fa fa-sort-down"></i>
              </span>
              <span (click)="SortVtc('asc')" *ngIf="DescVtc == true">
                <i class="fa fa-sort-up"></i>
              </span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of data">
          <td class="productalign">
            {{ item.domain_app_type }}
          </td>
          <td class="text_allign">{{ item.totalimpswon | number : 0 }}</td>
          <!-- <td class="text_allign">{{ item.totalclicks | number : 0 }}</td> -->
          <td class="text_allign" *ngIf="this.showconversions == true">
            {{ item.totalConversions | number : 0 }}
          </td>
          <td class="text_allign" *ngIf="this.showvtc == true">
            {{ item.VTC | number : 0 }}
          </td>
        </tr>
        <tr *ngIf="data.length == 0">
          <td colspan="3" style="text-align: center">
            <h4 style="border: 1px solid; padding: 1px">NO DATA</h4>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
