<div class="relative">
    <!-- <div class="form-bg"> -->
        <div class="pagenotfound">
        <div class="relative error-wrapper">
            <!-- <h1>
                Oops!</h1> -->
            <!-- <span class="error-text">404</span> -->
            <h2>
                Not Found</h2>
            <div class="error-details">
                <p> Sorry, an error has occured, Requested page not found!</p>
            </div>
            <div class="error-actions">
                <!-- <button class="mat-blue" mat-raised-button>Take me to home</button> -->
            </div>
        </div>
    </div>
</div>