<div id="content" class="print">
  <div class="row topbannerstyle">
    <div class="col-md-10">
      <h3>
        <strong><em>CAMPUS BOX MEDIA </em></strong>
      </h3>
      <h4>{{ this.campaignName }}</h4>
      <!-- <h4>{{ this.SponsorNameToParent }}</h4> -->
    </div>
    <div class="col-md-2">
      <!-- <button
        *ngIf="_rptRequest"
        (click)="exportToPDF()"
        id="exportToPDF"
        type="button"
        mat-raised-button
        class="btn mr-2"
        style="margin-top: 10px; color: white; background-color: #ff0043"
        [disabled]="loading"
      >
        <mat-icon>file_download</mat-icon>&nbsp;
        <span *ngIf="!loading"><b>DOWNLOAD</b></span>
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        <span *ngIf="loading"><b>DOWNLOADING...</b></span>
      </button> -->

      <button
      *ngIf="_rptRequest"
      (click)="exportToPDF()"
      id="exportToPDF"
      type="button"
      mat-raised-button
      class="btn mr-2"
      style="margin-top: 10px; color: white; background-color: #ff0043"
      [disabled]="loading"
    >
      <mat-icon>file_download</mat-icon>&nbsp;
      <span *ngIf="!loading"><b>DOWNLOAD</b></span>
      <span
        *ngIf="loading"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      <span *ngIf="loading"><b>DOWNLOADING...</b></span>
    </button>
    </div>
  </div>
  <br />
  
  <!-- Drop downs End -->
  <div class="d-flex flex-row">
    <div
      fxFlex.gt-sm="100"
      fxFlex.gt-xs="100"
      fxFlex="100"
      [class.hideOnLoadBannerAd]="!this.showBannerAdData"
    >
      <mat-card [class.hideOnLoadBannerAd]="true"  style="margin-bottom: 40px !important; break-after:page">
        <mat-card-title class="cardbgd">
          <div class="row">
            <div class="col-md-6">YOUR PACKAGE</div>
          </div>
        </mat-card-title>
        <mat-card-content>
          <App-yourpackage></App-yourpackage>
        </mat-card-content>
      </mat-card>

      <mat-card style="margin-bottom: 40px !important; ">
        <mat-card-title class="cardbgd">
          <div class="row">
            <div class="col-md-6">GEOFENCING CAMPAIGN</div>
          </div>
        </mat-card-title>
        <mat-card-content>
          <App-BannerAdCreativePerformance (SponsorNameToParent)="getPackageName($event)"></App-BannerAdCreativePerformance>
        </mat-card-content>
      </mat-card>
      <!-- Campaign Geography Start -->
      <mat-card style="margin-bottom: 40px !important; break-after:page">
        <mat-card-title class="cardbgd">
          <div class="row">
            <div class="col-md-6">CAMPAIGN GEOGRAPHY</div>
          </div>
        </mat-card-title>
        <mat-card-content style="padding-top: 0px">
          <App-campaigngeography></App-campaigngeography>
        </mat-card-content>
      </mat-card>

      <!-- Campaign Geography End -->
      <div class="row" style="margin-bottom: 40px !important; break-after:page">
        <div class="col-md-12">
          <div class="col-md-7">
            <div
              class="row cardbgdByDevice"
              style="margin-right: 1px; margin-left: 1px"
            >
              <div class="col-md-6" style="float: left">
                PERFORMANCE BY DEVICE
              </div>
            </div>
            <App-BannerAdCreativePerformanceByDevice>
            </App-BannerAdCreativePerformanceByDevice>
          </div>
          <div class="col-md-5" style="break-before:page">
            <div
              class="row cardbgdByDevice"
              style="margin-right: 1px; margin-left: 1px"
            >
              <div class="col-md-6" style="float: left">TOP DOMAINS</div>
            </div>
            <App-TopDomain></App-TopDomain>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
