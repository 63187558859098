import { RestDataSource } from "app/model/rest.datasource";
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
// import { EventEmitter } from "stream";

@Component({
  selector: "App-TopDomain",
  templateUrl: "./topdomains.component.html",
  styleUrls: ["./topdomains.component.scss"],
})
export class TopDomainComponent implements OnInit {
  data: any;
  BNamesData: any[];
  BNamesDatakeyword: string;
  ActualTopChannelsData: any[];
  AscChannel = true;
  DescChannel = false;
  AscImpressions = true;
  DescImpressions = false;
  AscClicks = true;
  DescClicks = false;
  AscConversions = true;
  DescConversions = false;
  AscVtc = true;
  DescVtc = false;

  pixelVerificationdone;
  conversionVerificationdone;
  showconversions: boolean;
  showvtc: boolean;
  showSpinner: boolean = true;
  sumOfConversions = 0;
  sumofVTC = 0;

  CampaignForm: FormGroup;

  constructor(
    private restDataSource: RestDataSource,
    private fb: FormBuilder,
    private el: ElementRef
  ) {}
  @Input() lineitemidFromParent: any;
  @Input() dropdownList_flightsFromparent: any;
  @Input() userIdFromparent: any;
  @Output() topdomainsUpdatedOn: EventEmitter<any> = new EventEmitter();

  update_topdomainsData(data1, data2, data3, data4): void {
    this.GetTopDomainsDataList(data1, data2, data3);
  }
  displayedColumnsBannerTopDomains: string[] = [
    "DOMAIN APP TYPE",
    "IMPRESSIONS",
  ];

  ngOnInit() {
    this.data = [];
    this.CampaignForm = this.fb.group({});
    this.CampaignForm = new FormGroup({
      Tactic_Name: new FormControl("", []),
    });
    this.showSpinner = false;
  }

  SortChannel(order) {
    if (order == "desc") {
      this.AscChannel = false;
      this.DescChannel = true;
      let sortedCompany = this.data.sort((a, b) =>
        a.domain_app_type < b.domain_app_type ? -1 : 1
      );
    }
    if (order == "asc") {
      this.AscChannel = true;
      this.DescChannel = false;
      let sortedCompany = this.data.sort((a, b) =>
        a.domain_app_type > b.domain_app_type ? -1 : 1
      );
    }
  }
  SortImpressions(order) {
    if (order == "desc") {
      this.AscImpressions = false;
      this.DescImpressions = true;
      let sortedCompany = this.data.sort((a, b) =>
        a.totalimpswon < b.totalimpswon ? -1 : 1
      );
    }
    if (order == "asc") {
      this.AscImpressions = true;
      this.DescImpressions = false;
      let sortedCompany = this.data.sort((a, b) =>
        a.totalimpswon > b.totalimpswon ? -1 : 1
      );
    }
  }
  SortClicks(order) {
    if (order == "desc") {
      this.AscClicks = false;
      this.DescClicks = true;
      let sortedCompany = this.data.sort((a, b) =>
        a.totalclicks < b.totalclicks ? -1 : 1
      );
    }
    if (order == "asc") {
      this.AscClicks = true;
      this.DescClicks = false;
      let sortedCompany = this.data.sort((a, b) =>
        a.totalclicks > b.totalclicks ? -1 : 1
      );
    }
  }
  SortConversions(order) {
    if (order == "desc") {
      this.AscConversions = false;
      this.DescConversions = true;
      let sortedCompany = this.data.sort((a, b) =>
        a.totalConversions < b.totalConversions ? -1 : 1
      );
    }
    if (order == "asc") {
      this.AscConversions = true;
      this.DescConversions = false;
      let sortedCompany = this.data.sort((a, b) =>
        a.totalConversions > b.totalConversions ? -1 : 1
      );
    }
  }
  SortVtc(order) {
    if (order == "desc") {
      this.AscVtc = false;
      this.DescVtc = true;
      let sortedCompany = this.data.sort((a, b) => (a.VTC < b.VTC ? -1 : 1));
    }
    if (order == "asc") {
      this.AscVtc = true;
      this.DescVtc = false;
      let sortedCompany = this.data.sort((a, b) => (a.VTC > b.VTC ? -1 : 1));
    }
  }

  GetTopDomainsDataList(
    lineitemidFromParent,
    dropdownList_flightsFromparent,
    userIdFromparent
  ) {
    this.GetTacticIdList(
      lineitemidFromParent,
      dropdownList_flightsFromparent,
      userIdFromparent
    );
  }

  GetTacticIdList(lineitem, flight, userIdFromparent) {
    this.showSpinner = true;

    this.restDataSource
      .GetTopDomainsDataList(lineitem, flight, userIdFromparent)
      .subscribe((data) => {
        var conversionflag;
        if (data != null || data != undefined) {
          this.data = data.data;
          this.ActualTopChannelsData = data;
        }

        this.sumOfConversions = 0;
        this.sumofVTC = 0;
        if (this.data != undefined && this.data.length > 0) {
          conversionflag = this.data[0].conversionFlag;
          this.showSpinner = false;
          this.topdomainsUpdatedOn.emit(this.data[0].updatedOn);

          for (let i = 0; i < this.data.length; i++) {
            this.sumOfConversions += this.data[i].totalConversions;
            this.sumofVTC += this.data[i].VTC;
          }
        } else {
          conversionflag = "";
          this.showSpinner = false;
          this.sumOfConversions = 0;
          this.sumofVTC = 0;
          this.topdomainsUpdatedOn.emit("");
        }
        let pixelVerification;
        let conversionVerification;
       
      });
  }

  
}
