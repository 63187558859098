import { Component, OnInit,ViewEncapsulation }      from '@angular/core';

@Component({
    selector: 'ms-errorpage',
  	templateUrl:'./errorpage-component.html',
 	  styleUrls: ['./errorpage-component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class errorPageComponent implements OnInit {

	constructor() {}

   	ngOnInit() {

   	}

 
}


