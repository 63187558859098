<div fxLayout="row wrap" fxLayoutAlign="center start" class="pt-2 " >
  <div style="margin-top: 1%;" fxFlex.sm="50" fxFlex.xs="60" fxFlex.md="45" fxFlex.lg="60" fxFlex.xl="45" fxFlex="50">
  
    <mat-card class="login-session">
      <div class="text-center">
        <h2 class="base-border mat-text-warn" style="color:#ff931e;">
          <mat-icon>vpn_key</mat-icon>&nbsp; Change Password
        </h2>
        <form [formGroup]="changepasswordForm" #formDir="ngForm" (ngSubmit)="onSubmit(changepasswordForm)">
          <div fxLayout="column" fxLayoutAlign="start stretch">
            <mat-form-field style="width: 100%">
              <input matInput id="OldPassword" class="form-control" type="Password" formControlName="OldPassword"
                placeholder="Current Password" required>
            </mat-form-field>
            <div *ngIf="OldPassword.invalid && (OldPassword.dirty || OldPassword.touched)" class="messages text-danger">
              <div style="color: red;" *ngIf="OldPassword.errors.required">
                Current Password is required.
              </div>
            </div>
            <mat-form-field style="width: 100%">

              <input matInput id="NewPassword" class="form-control" type="Password" formControlName="NewPassword"
                placeholder="New Password" required>
            </mat-form-field>
            <div *ngIf="NewPassword.invalid && (NewPassword.dirty || NewPassword.touched)" class="messages text-danger">
              <div style="color: red;" *ngIf="NewPassword.errors.required">
                New Password is required.
              </div>
              <div style="color: red;" *ngIf="NewPassword.errors.pattern">
                <ul style="text-align: left;">
                  <li>
                    Must be at least 8 characters
                  </li>
                  <li> Include at least 1 number
                  </li>
                  <li> Must contain at least one lowercase, one upper case letter
                    <!-- </li><li> Not contain any spaces -->
                  </li>
                  <li> Must contain at least one special character such as $ <> ^ ! #
                      <!-- </li><li> "We know it is a lot, but it is to safeguard your health privacy!  -->
                  </li>
                </ul>
              </div>
            </div>

            <mat-form-field style="width: 100%">

              <input matInput id="ConfirmPassword" class="form-control" type="Password"
                formControlName="ConfirmPassword" placeholder="Confirm New Password" required>
            </mat-form-field>
            <div *ngIf="ConfirmPassword.invalid && (ConfirmPassword.dirty || ConfirmPassword.touched)"
              class="messages text-danger">

              <div style="color: red;" *ngIf="ConfirmPassword.errors.required">
                Confirm Password is required.
              </div>
              <div style="color: red;" *ngIf="changepasswordForm.controls.ConfirmPassword.errors?.equalTo">The
                old and new
                passwords does not match</div>
            </div>
            <div style="color: red;" *ngIf="apiResponseError" class="messages text-danger">
              {{errorMessage}}
            </div>

            <button mat-raised-button type="submit" [disabled]="changepasswordForm.invalid"
              class="btn-md btn-block waves-effect text-center m-b-20"
              style="  background-color: #15a8eb;color: white;">Change Password&nbsp;&nbsp; <i
                class="fa fa-spinner fa-spin" *ngIf="showProgress"></i></button>
            <br>
            <br>
          </div>
        </form>
      </div>
    </mat-card>
  </div>
</div>