import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HttpClient } from "@angular/common/http";

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateService } from "@ngx-translate/core";
import { FlexLayoutModule } from "@angular/flex-layout";

import { DemoMaterialModule } from "./shared/demo.module";

import { MatSidenavModule } from "@angular/material/sidenav";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatCardModule } from "@angular/material/card";

import { AppRoutes } from "./app.routing";
import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { SharedModule } from "./shared/shared.module";
import { NgxPowerBiModule } from "ngx-powerbi";
import { AppSettings } from "./app.settings";

import { RestDataSource } from "./model/rest.datasource";
import { AuthService } from "./model/auth.service";
import { DataService } from "./Service/data.service";

import { NgxPrintModule } from "ngx-print";
import { CommonModule } from "@angular/common";

import { ChangepasswordComponent } from "./changepassword/changepassword.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { MapLeafletComponent } from "./maps/map-leaflet/map-leaflet.component";

import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { AccountSuspendedComponent } from "./account-suspended/account-suspended.component";
import { MatTableModule } from "@angular/material/table";
import { AgmCoreModule } from "@agm/core";
import { ChartsModule } from "ng2-charts";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { CampaignNativeReportingCopyComponent } from "./CampaignNativeReportingCopy/campaignnativereportingcopy.component";
import { BannerAdCreativePerformanceByDevice } from "./CampaignNativeReportingCopy/BannerAdPerformanceByDevice/banneradcreativeperformancebydevice.component";
import { TopDomainComponent } from "./CampaignNativeReportingCopy/TopDomains/topdomains.component";
import { BannerAdCreativePerformance } from "./CampaignNativeReportingCopy/BannerAdCreativePerformance/banneradcreativeperformance.component";
import { GeoMapComponent } from "./CampaignNativeReportingCopy/GeoMap/GeoMap.component";
import { YourPackageComponent } from "./CampaignNativeReportingCopy/YourPackage/yourpackage.component";
import { CampaignGeographyComponent } from "./CampaignNativeReportingCopy/CampaignGeography/campaigngeography.component";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    ChangepasswordComponent,
    MapLeafletComponent,
    AccountSuspendedComponent,
    CampaignNativeReportingCopyComponent,
    BannerAdCreativePerformanceByDevice,
    TopDomainComponent,
    BannerAdCreativePerformance,
    GeoMapComponent,
    YourPackageComponent,
    CampaignGeographyComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    SharedModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    ChartsModule,
    NgxChartsModule,
    RouterModule.forRoot(AppRoutes, {
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "legacy",
    }),
    FormsModule,
    MatSidenavModule,
    MatInputModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatToolbarModule,
    MatTabsModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatSelectModule,
    MatCardModule,
    DemoMaterialModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAV_GIwzxo2UE_lDZADdDGYws4CbHN8Woc",
    }),
    FlexLayoutModule,
    PerfectScrollbarModule,
    NgxPowerBiModule,
    NgxPrintModule,
    NgxDatatableModule,
    LeafletModule,
    MatTableModule,
    NgMultiSelectDropDownModule.forRoot(),

    //NgApexchartsModule,
  ],
  providers: [
    RestDataSource,
    AuthService,
    NgxPowerBiModule,
    DataService,
    TranslateService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
