import { RestDataSource } from "app/model/rest.datasource";
import { single, multi } from "./../chartlib/charts.data";
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Inject,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import { BannerAdCreativePerformanceByDevice } from "./BannerAdPerformanceByDevice/banneradcreativeperformancebydevice.component";
import { TopDomainComponent } from "./TopDomains/topdomains.component";
import { BannerAdCreativePerformance } from "./BannerAdCreativePerformance/banneradcreativeperformance.component";
import { GeoMapComponent } from "./GeoMap/GeoMap.component";
import Swal from "ngx-angular8-sweetalert2";
import * as _ from "lodash";

import "ngx-angular8-sweetalert2/src/sweetalert2.scss";

import html2canvas from "html2canvas";
import { element } from "protractor";
import jspdf, { jsPDF } from "jspdf";

import { DOCUMENT } from "@angular/common";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { YourPackageComponent } from "./YourPackage/yourpackage.component";
import { CampaignGeographyComponent } from "./CampaignGeography/campaigngeography.component";

@Component({
  selector: "app-campaignnativereportingcopy",
  templateUrl: "./campaignnativereportingcopy.component.html",
  styleUrls: ["./campaignnativereportingcopy.component.scss"],
})
export class CampaignNativeReportingCopyComponent implements OnInit {
  public single: any[];
  public multi: any[];

  public showXAxis = true;
  public showYAxis = true;
  public gradient = false;
  public showLegend = true;
  public showXAxisLabel = true;
  public xAxisLabel = "Date";
  public showYAxisLabel = true;
  public yAxisLabel = "Performance";
  public colorScheme = {
    domain: ["#673ab7", "#f44336", "#009688 ", "#2196f3"],
  };

  public showXAxisMedia = true;
  public showYAxisMedia = true;
  public gradientMedia = false;
  public showLegendMedia = true;
  public showXAxisLabelMedia = true;
  public xAxisLabelMedia = "";
  public showYAxisLabelMedia = true;
  public yAxisLabelMedia = "";
  public colorSchemeMedia = {
    domain: ["#f44336", "#2196f3", "#2196f3", "#2196f3", "#2196f3"],
  };
  showDataLabelMedia: boolean = true;
  public autoScale = true;
  lineChartData2: any = [];
  lineChartData1: any = [];
  totalPosts: any;
  counts: any;
  lineChartData: any = [];

  width = 550;
  height = 400;
  BCILineItems: any;
  BNamesData: any[];
  BNamesDatakeyword: string;
  allProducts: any = [];
  productData: any = [];
  productData1: any = [];
  newData: any = [];
  params: any;
  check: any = [];
  userId: any;
  lineItemId: any;
  _disableLineItemName: boolean = false;
  _disableFlightRange: boolean = false;
  userSharePageResponse: any;
  message: any;
  _rptRequest: boolean = true;
  _showExtRequest: boolean = false;
  _extLinkError: boolean = false;
  campaignName: any;
  flightRange: any;
  productData2: any = [];
  sharedRptRequest: boolean = false;
  productData3: any = [];
  showSpinner: boolean;
  allProductsConnected: any = [];
  newDataConnected: any = [];
  lineChartDataConnected: any = [];
  productDataC1: any = [];
  productDataC3: any = [];
  productDataC: any = [];
  showSpinnerC: boolean;
  spinnerDiameter: number;
  bannerByTactic: any = [];

  displayedColumnsVideosTopChannels: string[] = ["CHANNEL/APP", "IMPRESSIONS"];
  connectedTVByTactic: any = [];
  bannerByDevice: any = [];
  BannerTopDomain: any = [];
  videosByDevice: any = [];
  VideoChannels: any = [];
  bannerMD: any = [];
  barChartData: any = [];
  newDataBarChart1: any = [];
  bannerMDData: any = [];
  bannerMD1: any = [];
  BCIFlights: any = [];
  FlightsData: any[];
  BNamesDatakeyword1: string;
  FlightsAllFlag: boolean = true;
  LineItemId: string;
  selectedValue: string;
  selected: string;
  LineItemName: string;
  CampaignForm: FormGroup;
  LineItemId1: string[];
  public selected1 = "d826a5";
  selected2 = "d826a5";
  lineitemidToChild: any;

  dropdownList: any;
  dropdownList_flights: any;
  dropdownSettings;
  dropdownSettings1;
  dropdownSettings_flights;
  selectedItems = [];
  modelForMultiSelect: any = {};
  BNamesData1 = [];
  FlightsDropdownData = [];
  public showPgBrkLines = true;

  LineItemNameToChildComponent: any;
  dropdownList_flights_ToChildComponent: any;
  LoggedInUserId;
  ClientLogo: any;

  @ViewChild(BannerAdCreativePerformanceByDevice, { static: false })
  child_updatebanerperformacebydevice: BannerAdCreativePerformanceByDevice;

  @ViewChild(TopDomainComponent, { static: false })
  child_update_topdomains: TopDomainComponent;

  @ViewChild(BannerAdCreativePerformance, { static: false })
  child_updatebanerAdcreativeperformnce: BannerAdCreativePerformance;

  @ViewChild(YourPackageComponent, { static: false })
  child_update_yourpackage: YourPackageComponent;

  @ViewChild(CampaignGeographyComponent, { static: false })
  child_update_campaigngeography: CampaignGeographyComponent;
  // @ViewChild(GeoMapComponent, { static: false })
  // child_updateGeoMap: GeoMapComponent;

  statusinfo: string;
  BannerAdstatusinfo: boolean;
  Ctvstatusinfo: boolean;
  showBannerAdData: boolean = true;
  showConnectedTvData: boolean = true;
  showFullContent: boolean = true;
  min_width = 1;
  topdomainsUpdatedOn: any;
  topChannelsUpdatedOn: any;
  BannerAdMediaComparisionUpdatedOn: any;
  CtvMediaComparisionUpdatedOn: any;
  CtvPerformanceByDeviceUpdatedOn: any;
  BannerPerformanceByDeviceUpdatedOn: any;
  CtvPerformanceByTacticUpdatedOn: any;
  BannerAdPerformanceByTacticUpdatedOn: any;
  HeatMapUpdatedOn: any;
  PackageName: any;
  BannerAdUpdatedOn: any;
  pgb1: boolean = false;

  CtvPerformanceUpdatedOn: any;
  BannerAdPerformanceUpdatedOn: any;

  showfirstDiv: boolean;
  showsecondDiv: boolean;
  loading: boolean = false;
  zerothindex;
  firstindex;

  //Map
  geographyMapData: any = [];
  geoDescription: any = "";
  modelPopup: boolean;
  show: boolean = false;
  StrategyRequest: string;
  showmap: boolean = false;
  bounds: boolean = false;
  setZoom: any = [];
  result: any;
  polygon: any;
  fitBounds: boolean = false;
  pathsOfPolyArr: any = [];
  fitZoomLevel: any = [];
  SponsorNameToParent : any;
  lineItem = "";
  constructor(
    private restDataSource: RestDataSource,

    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    Object.assign(this, { single, multi });
  }
  onSelectFlight(value) {}

  onSelectCampaign(value) {}

  onItemSelectFlight(items: any) {
    this.dropdownList_flights_ToChildComponent =
      "'" + this.dropdownList_flights.map((x) => x.item_id).join("', '") + "'";
  }
  onItemUnSelectFlight(item: any) {
    for (let i = 0; i < this.dropdownList_flights.length; i++) {
      if (this.dropdownList_flights[i].item_id == item.item_id) {
        this.dropdownList_flights.splice(i, 1);
      }
    }
    this.dropdownList_flights_ToChildComponent =
      "'" + this.dropdownList_flights.map((x) => x.item_id).join("', '") + "'";
  }

  onSelectAllFlight(items: any) {
    this.dropdownList_flights = [];
    this.dropdownList_flights = items;
    this.dropdownList_flights_ToChildComponent =
      "'" + this.dropdownList_flights.map((x) => x.item_id).join("', '") + "'";
  }

  onFilters(value, source, $event) {
    this.lineItemId = value;
    if (this.lineItemId.indexOf("'") >= 0) {
      //do something
    } else {
      this.lineItemId = "'" + this.lineItemId + "'";
    }
    this.LineItemNameToChildComponent = this.lineItemId;
    this.getBannerAdAndCtvInfoData();
    // try {
    //   this.FlightsData = [];
    //   this.LineItemNameToChildComponent = this.lineItemId;
    //   if (
    //     (_.isEmpty(this.params) && this.flightRange == "") ||
    //     (!_.isEmpty(this.params) && this.flightRange == "")
    //   ) {
    //     this.restDataSource
    //       .ListOfFlightsById(this.userId, this.lineItemId)
    //       .subscribe((data) => {
    //         this.BCIFlights = data;
    //         this.FlightsData = [];
    //         this.BNamesDatakeyword1 = "DateRange";
    //         this.FlightsDropdownData = [];
    //         for (var k = 0; k < this.BCIFlights.length; k++) {
    //           const m1: any = {};
    //           m1.item_id = this.BCIFlights[k].DateRange;
    //           m1.item_text = this.BCIFlights[k].DateRange;
    //           this.FlightsDropdownData.push(m1);
    //         }

    //         const m3: any = {};
    //         const m4: any = [];
    //         if (this.BCIFlights.length > 0) {
    //           m3.item_id = this.BCIFlights[0].DateRange;
    //           m3.item_text = this.BCIFlights[0].DateRange;
    //           m4.push(m3);
    //           this.dropdownList_flights = m4;
    //           this.dropdownList_flights_ToChildComponent =
    //             "'" +
    //             this.dropdownList_flights.map((x) => x.item_id).join("', '") +
    //             "'";
    //         } else {
    //           this.dropdownList_flights_ToChildComponent = "";
    //         }

    //         if (source == "OnInIt") {
    //           this.getBannerAdAndCtvInfoData();
    //           // this.child_updateGeoMap.update_GeoMap(
    //           //   this.LineItemNameToChildComponent,
    //           //   this.dropdownList_flights_ToChildComponent,
    //           //   this.userId,
    //           //   this.campaignName,
    //           //   this.sharedRptRequest
    //           // );
    //         }
    //       });
    //   } else if (!_.isEmpty(this.params)) {
    //     this.FlightsDropdownData = [];
    //     this.check = [];
    //     this.flightRange = this.flightRange.split("',");
    //     for (let i = 0; i < this.flightRange.length; i++) {
    //       let daterange = this.flightRange[i].replace(/['‘’"“”]/g, "").trim();
    //       const dr1 = {
    //         item_id: daterange,
    //         item_text: daterange,
    //       };
    //       this.FlightsDropdownData.push(dr1);
    //       this.check.push(dr1);
    //     }
    //     this.dropdownList_flights = this.check;

    //     if (source == "OnInIt") {
    //       this.getBannerAdAndCtvInfoData();
    //       // this.child_updateGeoMap.update_GeoMap(
    //       //   this.LineItemNameToChildComponent,
    //       //   this.dropdownList_flights_ToChildComponent,
    //       //   this.userId,
    //       //   this.campaignName,
    //       //   this.sharedRptRequest
    //       // );
    //     }
    //   }
    // } catch (error) {}
  }

  getBannerAdAndCtvInfoData() {
    this.restDataSource
      .getBannerAdAndCtvInfoData(
        this.LineItemNameToChildComponent,
        this.dropdownList_flights_ToChildComponent,
        this.LoggedInUserId
      )
      .subscribe((data) => {
        this.child_updatebanerperformacebydevice.update_BannerAdCreativePerformanceByDevice(
          this.LineItemNameToChildComponent,
          this.dropdownList_flights_ToChildComponent,
          this.LoggedInUserId,
          this.campaignName
        );

        this.child_update_topdomains.update_topdomainsData(
          this.LineItemNameToChildComponent,
          this.dropdownList_flights_ToChildComponent,
          this.LoggedInUserId,
          this.campaignName
        );

        this.child_updatebanerAdcreativeperformnce.update_BannerAdCreativePerformance(
          this.LineItemNameToChildComponent,
          this.dropdownList_flights_ToChildComponent,
          this.LoggedInUserId,
          this.campaignName,
          this.sharedRptRequest
        );

        this.child_update_yourpackage.update_YourPackage(
          this.LineItemNameToChildComponent
        );
        this.child_update_campaigngeography.update_CampaignGeography(
          this.LineItemNameToChildComponent
        );
        //this.geteographyList();
      });
  }
  // onRcddMsgFromUsersAddChild(data) {
  //   this.child.reloadMap(this.geographyMapData);
  // }
  // clearGeograpyData() {
  //   this.child.ngOnInit();
  // }
  geteographyList() {
    // Latest Map Code
    this.showmap = false;
    this.bounds = false;
    this.fitBounds = false;
    this.result = [];
    this.setZoom = [];
    this.polygon = null;
    // this.pathsOfPolyArr = [];
    // this.fitZoomLevel=[];
    this.geographyMapData = [];
    this.geoDescription = "";
    this.lineItem = this.LineItemNameToChildComponent;
    // this.restDataSource
    //   .getGeographyByLineItemId(this.lineItem)
    //   .subscribe((data) => {
    //     var zipCode = "";
    //     if (data != null && data != undefined) {
    //       if (data.length > 0) {
    //         var status = "";
    //         status = data[0].Status;
    //         if (status == "Publish") {
    //           if (
    //             data[0].geographyStatus == "zipCode" ||
    //             data[0].geographyStatus == "State" ||
    //             data[0].geographyStatus == "DMA" ||
    //             data[0].geographyStatus == "City" ||
    //             data[0].geographyStatus == "Country"
    //           ) {
    //             this.result = data;
    //             let i = 0;
    //             let Mlen = this.result.length;
    //             while (i < Mlen) {
    //               this.polygon = Function("return" + this.result[i].Polygon)();
    //               this.result[i].Polygon = this.polygon;
    //               let m = 0;
    //               let Polylength = this.polygon.length;
    //               while (m < Polylength) {
    //                 let j = 0;
    //                 let len = this.polygon[m].length;
    //                 if (data[0].geographyStatus == "Country") {
    //                   while (j < len) {
    //                     this.setZoom.push(this.polygon[m][j]);
    //                     j++;
    //                     if (len > 5) {
    //                       break;
    //                     }
    //                   }
    //                 } else {
    //                   while (j < len) {
    //                     this.setZoom.push(this.polygon[m][j]);
    //                     j++;
    //                     if (Mlen > 5) {
    //                       break;
    //                     }
    //                   }
    //                 }
    //                 m++;
    //               }
    //               if (
    //                 this.result[i].Description != "" &&
    //                 this.result[i].Description != null
    //               ) {
    //                 this.geoDescription = this.result[i].Description;
    //               }
    //               i++;
    //             }
    //             this.showmap = true;
    //             this.bounds = true;
    //             this.fitBounds = true;
    //           }
    //         }
    //       }
    //     }

    //     // this.onRcddMsgFromUsersAddChild(this.geographyMapData);
    //   });
  }
  // exportToPDF() {
  //   //this.pgb1 = true;
  //   this.showPgBrkLines = true;
  //   this.loading = true;
  //   const htmlWidth = $("#content").width();
  //   const htmlHeight = $("#content").height();

  //   const topLeftMargin = 15;

  //   let pdfWidth = htmlWidth + topLeftMargin * 2;
  //   // let pdfHeight = pdfWidth * 4 + topLeftMargin * 2;
  //   let pdfHeight = pdfWidth * 1 + topLeftMargin * 2;

  //   const canvasImageWidth = htmlWidth;
  //   const canvasImageHeight = htmlHeight;

  //   const totalPDFPages = Math.ceil(htmlHeight / pdfHeight) - 1;

  //   var pg1imgData, pg1canvas, pg1canvas_height, pg1canvas_width;
  //   var pg2imgData, pg2canvas, pg2canvas_height, pg2canvas_width;
  //   var pg3imgData, pg3canvas, pg3canvas_height, pg3canvas_width;

  //   const data = this.document.getElementById("content");
  //   const iframes = document.querySelectorAll("iframe");

  //   for (let i = 0; i < iframes.length; i++) {
  //     // Create a new img element
  //     const img = document.createElement("img");

  //     // Set the src attribute of the img element to the iframe's src
  //     img.src =
  //       "https://sophosmarketingstorage.blob.core.windows.net/pdfdownloadimages/PDF_Image_Placeholder.png";
  //     img.width = 260;
  //     img.height = 217;
  //     // iframes[i].src;
  //     // img.src =
  //     //   "https://sophosmarketingstorage.blob.core.windows.net/pdfdownloadimages/PDF_Image_Placeholder.png";
  //     // Replace the iframe element with the img element
  //     iframes[i].replaceWith(img);
  //   }
  //   // Select the video element
  //   const videos = document.querySelectorAll("video");
  //   for (let i = 0; i < videos.length; i++) {
  //     // Create a new img element
  //     const img = document.createElement("img");

  //     // Set the src attribute of the img element to the poster image of the video
  //     img.src =
  //       "https://sophosmarketingstorage.blob.core.windows.net/pdfdownloadimages/PDF_CTV_placeholder.png";
  //     img.height = 232;
  //     img.width = 390;
  //     // videos[i].poster;

  //     // Replace the video element with the img element
  //     videos[i].replaceWith(img);
  //   }

  //   html2canvas(data, {
  //     useCORS: true,
  //     // width: 1820,
  //     // height: 9002
  //     //       scale: 0.8
  //   }).then((canvas) => {
  //     // this.showPgBrkLines=false;
  //     canvas.getContext("2d");
  //     const imgData = canvas.toDataURL("image/jpeg;base64");
  //     let pdf = new jsPDF("p", "pt", [pdfWidth, pdfHeight]);
  //     // **** full page compressed showed in 25%

  //     var extra_canvas = document.createElement("canvas");
  //     extra_canvas.setAttribute("width", canvas.width.toString());
  //     extra_canvas.setAttribute("height", "1");

  //     var ctx = extra_canvas.getContext("2d");
  //     var mk = 1;
  //     // for(var m=200;m<1300;m+=200) {
  //     ctx.drawImage(canvas, 200, 0, 100, 1, 0, 0, 100, 1);
  //     var dataURL = extra_canvas.toDataURL();
  //     var pdfimageheight;
  //     var prvm = 0,
  //       pg = 1,
  //       plusval,
  //       imgheight,
  //       imgy;
  //     plusval = 100;
  //     for (var m = 100; m < canvas.height; m++) {
  //       ctx.drawImage(canvas, 200, m, 100, 1, 0, 0, 100, 1);
  //       var dataURL1 = extra_canvas.toDataURL();
  //       if (dataURL == dataURL1 && prvm + 1 != m) {
  //         imgheight = m - prvm;
  //         imgy = prvm;
  //         prvm = m;

  //         m += plusval;

  //         extra_canvas.setAttribute("height", imgheight.toString());
  //         ctx.drawImage(
  //           canvas,
  //           0,
  //           imgy,
  //           canvas.width,
  //           imgheight,
  //           0,
  //           0,
  //           canvas.width,
  //           imgheight
  //         );

  //         var dataURL2 = extra_canvas.toDataURL();
  //         if (pg > 1) pdf.addPage([pdfWidth, pdfHeight], "p");
  //         if (pg == 4) {
  //           pdfimageheight = pdfHeight / 1.2;
  //         } else {
  //           pdfimageheight = pdfHeight;
  //         }
  //         pdf.addImage(
  //           dataURL2,
  //           "png",
  //           topLeftMargin,
  //           0,
  //           canvasImageWidth,
  //           pdfimageheight
  //         );
  //         pg++;

  //         extra_canvas.setAttribute("height", "1");
  //       }
  //     }
  //     m = canvas.height;
  //     imgheight = m - prvm;
  //     imgy = prvm;
  //     extra_canvas.setAttribute("height", imgheight.toString());

  //     ctx.drawImage(
  //       canvas,
  //       0,
  //       imgy,
  //       canvas.width,
  //       imgheight,
  //       0,
  //       0,
  //       canvas.width,
  //       imgheight
  //     );
  //     var dataURL2 = extra_canvas.toDataURL();
  //     pdf.addPage([pdfWidth, pdfHeight], "p");
  //     pdf.addImage(
  //       dataURL2,
  //       "png",
  //       topLeftMargin,
  //       0,
  //       canvasImageWidth,
  //       pdfHeight
  //     );

  //     pdf.save(`CampusBox_Dashboard_${new Date().toLocaleString()}.pdf`);
  //     //this.showPgBrkLines = false;
  //     this.loading = false;
  //   });
  //   this.getBannerAdAndCtvInfoData();
  // }
  //   // 2nd try start
  exportToPDF() {



    // this._rptRequest = false;
    // window.print();
    // this._rptRequest = true;

      
      var printButton = document.getElementById("exportToPDF");
      //Set the print button visibility to 'hidden' 
      printButton.style.visibility = 'hidden';
      //Print the page content
      window.print()
      printButton.style.visibility = 'visible';



    // //this.pgb1 = true;
    // const htmlWidth = $("#content").width();
    // const htmlHeight = $("#content").height();

    // const topLeftMargin = 15;

    // let pdfWidth = htmlWidth + topLeftMargin * 2;
    // let pdfHeight = pdfWidth * 2 + topLeftMargin * 2;

    // const canvasImageWidth = htmlWidth;
    // const canvasImageHeight = htmlHeight;

    // const totalPDFPages = Math.ceil(htmlHeight / pdfHeight) - 1;
    // // scrollX: -window.scrollX,
    // // scrollY: -window.scrollY,
    // // windowWidth: document.documentElement.offsetWidth,
    // // windowHeight: data.scrollHeight,
    // const data = this.document.getElementById("content");
    // // start iframe
    // //const data = this.document.getElementById("content");
    // const iframes = document.querySelectorAll("iframe");

    
    // for (let i = 0; i < iframes.length; i++) {
    //   // Create a new img element
    //   const img = document.createElement("img");

    //   // Set the src attribute of the img element to the iframe's src
    //   img.src =
    //     "https://sophosmarketingstorage.blob.core.windows.net/pdfdownloadimages/PDF_Image_Placeholder.png";
    //   img.width = 260;
    //   img.height = 217;
    //   // iframes[i].src;
    //   // img.src =
    //   //   "https://sophosmarketingstorage.blob.core.windows.net/pdfdownloadimages/PDF_Image_Placeholder.png";
    //   // Replace the iframe element with the img element
    //   iframes[i].replaceWith(img);
    // }
    // // Select the video element
    // const videos = document.querySelectorAll("video");
    // for (let i = 0; i < videos.length; i++) {
    //   // Create a new img element
    //   const img = document.createElement("img");

    //   // Set the src attribute of the img element to the poster image of the video
    //   img.src =
    //     "https://sophosmarketingstorage.blob.core.windows.net/pdfdownloadimages/PDF_CTV_placeholder.png";
    //   img.height = 232;
    //   img.width = 390;
    //   img.crossOrigin = 'Anonymous';
    //   // videos[i].poster;

    //   // Replace the video element with the img element
    //   videos[i].replaceWith(img);
    // }
    // // end iframe
    // html2canvas(data, {
    //   useCORS: true,
      
    // }).then((canvas) => {
    //   canvas.getContext("2d");
    //   const imgData = canvas.toDataURL("image/jpeg;base64");
    //   let pdf = new jsPDF("p", "pt", [pdfWidth, pdfHeight]);
    //   pdf.addImage(
    //     imgData,
    //     "png",
    //     topLeftMargin,
    //     topLeftMargin,
    //     canvasImageWidth,
    //     canvasImageHeight
    //   );
    //   for (let i = 1; i <= totalPDFPages; i++) {
    //     pdf.addPage([pdfWidth, pdfHeight], "p");
    //     pdf.addImage(
    //       imgData,
    //       "png",
    //       topLeftMargin,
    //       -(pdfHeight * i) + topLeftMargin * 4,
    //       canvasImageWidth,
    //       canvasImageHeight
    //     );
    //   }
    //   pdf.save(`CampusBox_Dashboard_${new Date().toLocaleString()}.pdf`);
    // });
    // this.getBannerAdAndCtvInfoData();
    // //this.pgb1 = false;
  }

  async ngOnInit() {
    this.geteographyList();
    this.userId = "";
    this.campaignName = "";
    this.flightRange = "";
    this.lineItemId = "";
    this.dropdownList_flights_ToChildComponent = "";
    this.BNamesData = [];
    this._disableLineItemName = false;
    this._disableFlightRange = true;
    this._extLinkError = false;
    this.sharedRptRequest = false;
    this.CampaignForm = new FormGroup({
      CampaignName: new FormControl(this.campaignName, []),
    });

    this.activatedRoute.params.subscribe(async (params: Params) => {
      this.params = params;
    });
  
    if (!_.isEmpty(this.params)) {


      this._rptRequest = true;
      this._showExtRequest = true;
      this.message = "";
      this._disableLineItemName = true;
      let value ;
      if(this.params._token != null && this.params._token != undefined && this.params._token != ""){
        const model : any ={};
        // model.url= "www.campigns.campusbox.com";
        // model.pageName = "campusboxsharedpage";
        model.url= window.location.href;
        model._token = this.params._token;
        model._guid = this.params._guid;
        //  value = await this.restDataSource.validaterequestedurl(this.params);
        value = await this.restDataSource.validaterequestedurl(model);

      }else{
        value = await this.restDataSource.validatecampusboxsharedlinkHexCode(this.params);
      }
      // const value = await this.restDataSource.validatecampusboxsharedlinkHexCode(this.params);
if(value.resp != undefined && value.resp.successResponse != null){
    if( value.resp.successResponse.lineItemId != null && value.resp.successResponse.lineItemId != undefined && value.resp.successResponse.lineItemId !=""){
          this.lineItemId = value.resp.successResponse.lineItemId;
          this.campaignName = value.resp.successResponse.campaignName;
          this.LoggedInUserId = "";
          this.lineitemidToChild = value.resp.successResponse.lineItemId;
      this.onFilters(this.lineItemId, "OnInIt", "temp");
    }
}
else if(value.length > 0 && value[0].SharedLink != null && value[0].SharedLink != undefined && value[0].SharedLink != '') {
  let redirectUrl= new URL(value[0].SharedLink);
  let newurl = redirectUrl.pathname;
  this.router.navigate([newurl]); 
}
else{
    this.message = "Invalid URL";
          await Swal.fire({
            title : this.message,
            text: "The URL entered does not match an active report in our system. Email service@campusboxmedia.com for assistance.",
            icon: 'warning',
            allowOutsideClick: false,
          }).then((result) => {
            if (result.value) {
              this._extLinkError = true;
              this.router.navigate(["/errorpage"]);
            }
          });
}

    } else {
      this._extLinkError = false;
      this._disableFlightRange = false; 
      this._rptRequest = true;
      this.sharedRptRequest = false;
      this.userId = localStorage.getItem("UserId");
      this.LoggedInUserId = localStorage.getItem("UserId");
      this.campaignName = localStorage.getItem("CampaignName");
      this.lineItemId = localStorage.getItem("CampaignID");
      this.lineItemId = `'` + this.lineItemId + `'`;
      this.lineitemidToChild = this.lineItemId;
    }

    //end

    if (!this._extLinkError) {
      //this.GetUserDetails();

      this.LineItemName = this.campaignName;

      if (
        this.campaignName == undefined ||
        this.campaignName == null ||
        this.campaignName == "null"
      ) {
        this.CampaignForm.controls.CampaignName.setValue("");
      } else {
        this.CampaignForm.controls.CampaignName.setValue(this.campaignName);
      }

      this.FlightsData = [];
      if (_.isEmpty(this.params)) {
        this.restDataSource
          .NativeListOfLineItemsById(this.userId)
          .subscribe((data) => {
            this.BCILineItems = data;
            this.BNamesData = [];
            this.BNamesDatakeyword = "LineItemName";
            for (var j = 0; j < this.BCILineItems.length; j++) {
              this.BNamesData.push(this.BCILineItems[j]);
            }

            if (
              this.campaignName == undefined ||
              this.campaignName == null ||
              this.campaignName == "null"
            ) {
              this.CampaignForm.controls.CampaignName.setValue(
                this.BNamesData[0].LineItemName
              );
              localStorage.setItem(
                "CampaignName",
                this.BNamesData[0].LineItemName
              );
              localStorage.setItem("CampaignID", this.BNamesData[0].LineItemId);
              this.campaignName = this.BNamesData[0].LineItemName;
              this.lineItemId = this.BNamesData[0].LineItemId;
            }
            const model: any = {};
            model.LineItemId = this.lineItemId;
            model.LineItemName = this.campaignName;
          });
      }

      // this.onFilters(this.lineItemId, "OnInIt", "temp");
    }
  }

  getPackageName(SponsorNameToParent: any) {
    this.SponsorNameToParent = SponsorNameToParent;
  }


  GetUserDetails() {
    this.restDataSource
      .getUserDetailsByUserId(this.userId)
      .subscribe((data1) => {
        this.ClientLogo = data1[0].ClientLogo;
      });
  }
}
