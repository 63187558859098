
import config_json from "./../assets/data/app_settings.json";

export class AppSettings {
  public static APP_PAGELIMIT = 50;
  // // //local
//  public static API = "http://localhost:3000/";
//   public static API_ENDPOINT = "http://localhost:3000/api";
//   public static APP_OfficeClientID = "8d25573c-5954-4fee-9e41-77c1874a6c7a";
//   public static APP_RedirectUri = "http://localhost:4200/dashboard";
//   public static Environment = "Testing";
//   public static storageAccountURL =  "https://czstoretest.blob.core.windows.net/";
//   public static supportMailID = "admin@resumana.com";
//   public static APP_RedirectUrL = "https://dev-admin-fe-1.azurewebsites.net";

public static API = config_json.config.API;
public static API_ENDPOINT = config_json.config.API_ENDPOINT;
public static APP_OfficeClientID = config_json.config.APP_OfficeClientID;
public static APP_RedirectUri = config_json.config.APP_RedirectUri;
public static Environment = config_json.config.Environment;
public static storageAccountURL = config_json.config.storageAccountURL;
public static supportMailID = config_json.config.supportMailID;
public static APP_RedirectUrL = config_json.config.APP_RedirectUrL;

  // /* Local Ends */

  // /* Testing started */
  // public static Iframe_BaseURL =
  //   'https://dev-admin-fe-1.azurewebsites.net/czstore';
  // public static API_ENDPOINT =
  //   'https://dev-api-campaign-1.azurewebsites.net/api';
  // public static APP_OfficeClientID = '58ab565c-923b-4ab9-9eb3-b76d0a3e2e93';
  // public static APP_RedirectUri =
  //   'https://dev-admin-fe-1.azurewebsites.net/authentication/login';
  // public static Environment = 'Testing';
  // public static storageAccountURL =
  //   'https://czstoretest.blob.core.windows.net/';
  // public static supportMailID = 'admin@resumana.com';
  // public static APP_RedirectUrL = 'https://dev-admin-fe-1.azurewebsites.net';
  // /* Testing Ends */

  // pre-prod start
  // public static Iframe_BaseURL = 'https://dev-admin-fe-1.azurewebsites.net/czstore';
  // public static API_ENDPOINT =  'https://preprod-api-campaign-1.azurewebsites.net/api';
  // public static APP_OfficeClientID = '58ab565c-923b-4ab9-9eb3-b76d0a3e2e93';
  // public static APP_RedirectUri =  'https://dev-admin-fe-1.azurewebsites.net/authentication/login';
  // public static Environment = 'Testing';
  // public static storageAccountURL = 'https://czstoretest.blob.core.windows.net/';
  // public static supportMailID = 'admin@resumana.com';
  // public static APP_RedirectUrL = 'https://dev-admin-fe-1.azurewebsites.net';
  // pre-prod end

  //Native Report starts here 
  // public static Iframe_BaseURL = 'https://dev-admin-fe-1.azurewebsites.net/czstore';
  // public static API_ENDPOINT = 'https://dev-api-campaign-1.azurewebsites.net/api';
  // public static APP_OfficeClientID = '58ab565c-923b-4ab9-9eb3-b76d0a3e2e93';
  // public static APP_RedirectUri ='https://admin-fe-1.azurewebsites.net/authentication/login';
  // public static Environment = 'Testing';
  // public static storageAccountURL = 'https://czstoretest.blob.core.windows.net/';
  // public static supportMailID = 'admin@resumana.com';
  // public static APP_RedirectUrL = 'https://admin-fe-1.azurewebsites.net/';
  //Native Report ends here

  // /* Production started */
  //     public static Iframe_BaseURL = 'https://dashboard.awarity.com/czstore';
  //   public static API_ENDPOINT = 'https://api-campaign-1.azurewebsites.net/api';
  //   public static APP_OfficeClientID = "96c65ac1-5a5f-401b-9f15-3c1bc7739074";
  //   //public static APP_RedirectUri = " https://fe-campaign-2.azurewebsites.net/dashboard";
  //   public static APP_RedirectUri = "https://dashboard.awarity.com/authentication/login";
  //   public static Environment = "Production";
  //  public static storageAccountURL = 'https://sophosmarketingstorage.blob.core.windows.net/';
  // public static supportMailID = 'support@awarity.com';
  // public static APP_RedirectUrL = "https://dashboard.awarity.com/";
  // /* Production Ends */
}
