import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, FormControl, FormsModule,  Validators } from '@angular/forms';
import { Observable } from 'rxjs/Rx';
import { ChangePasswordModel } from './changepassword.interface';
//import { AuthenticateService } from '../authentication.service';

import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import {CustomValidators} from "ng2-validation";

import Swal from 'ngx-angular8-sweetalert2'
 
import 'ngx-angular8-sweetalert2/src/sweetalert2.scss'
import { AuthService } from 'app/model/auth.service';
@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss'],
})
export class ChangepasswordComponent implements OnInit {

  ChangePasswords = {};
  changepasswordForm: FormGroup;
  public token: string;
  public form: FormGroup;
  public resp: any;
  public tk: string;
  public urlparamtk: string;
  public successMessage: string;
  public showProgress = false;
  public apiResponseError = false;
  public errorMessage: string;
  public url:string;
  public urlText:string;
  formData: FormData;
  menuControlsValue:string;
  menuControlBoolean:boolean=true;

  navigationSubscription;
  flex_size : 45.33;
  // ResetPass: ResetPassword;
  // "((?=.*\d)(?=.*[a-zA-Z])(?=.*[0-9]).{6,8})"; -- good one with 1 a or A and 1 number
  // "((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,8})";  -- 1 a , 1 A, 1 Number - fine

public pwdPattern = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^0-9a-zA-Z])(?=.*?[^\w\s]).{8,}$";
  ResetPass = {OldPassword: '', NewPassword: '', ConfirmPassword: '' };


  constructor(private fb: FormBuilder, private auth: AuthService, private route: ActivatedRoute, private router: Router) {
    
    this.navigationSubscription = this.router.events.subscribe((e: any) => {

      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });

    const oldpass = new FormControl('', Validators.required);
    const newpass = new FormControl('', [Validators.required, Validators.pattern(this.pwdPattern)]);
    const confpass = new FormControl('', CustomValidators.equalTo(newpass));

    var user = JSON.parse(localStorage.getItem('currentUser'));
    if(user[0].RoleName=="Instructor")
    {
        this.url="/InstructorSchedules";
        this.urlText="Back";
    }
    else{
      this.url="/dashboard";
      this.urlText="Back to DashBoard";
    }

    this.changepasswordForm = new FormGroup({
      'OldPassword': oldpass,
      'NewPassword': newpass,
      'ConfirmPassword': confpass
      });
    }

 

  get OldPassword() { return this.changepasswordForm.get('OldPassword'); }
  get NewPassword() { return this.changepasswordForm.get('NewPassword'); }
  get ConfirmPassword() { return this.changepasswordForm.get('ConfirmPassword'); }

  initialiseInvites() {
    // for Menu reload item this function will be triggered
    // Set default values and re-fetch any data you need.
    
    this.ngOnInit();
  }
  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we  
    // don't then we will continue to run our initialiseInvites()   
    // method on every navigationEnd event.
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  ngOnInit() {

      this.menuControlsValue = localStorage.getItem('menuControls');
    
      
      this.menuControlBoolean =JSON.parse(this.menuControlsValue);
      this.onChanges();

  }

 

  onChanges(): void {
    this.changepasswordForm.valueChanges.subscribe(val => {
    this.apiResponseError = false;
    });
  }

 openSuccessSwal() {
  
  Swal.fire({
    title: 'Password changed successfully!',
    text: 'Try login with your new password.',
    icon: 'success',
  }).then((result) => {

    this.router.navigateByUrl('/authentication/login');
  })
}

  onSubmit({ value, valid }: { value: ChangePasswordModel, valid: boolean }) {
    this.showProgress = true;
    if (valid) {
      this.auth.ChangePasswordService(value).subscribe(resp => {
        if (resp == undefined || resp == null) {
          this.resp = resp;
          this.showProgress = false;
          this.openSuccessSwal();
          const test = localStorage.getItem('menuControls');
          if(test == 'true') 
          this.router.navigateByUrl('/authentication/admin');
          else
          this.router.navigateByUrl('/authentication/login');
        }else  if (this.resp.Message === 'The old password is incorrect. Please retype your password.') {
            this.apiResponseError = true;
            this.errorMessage = "Incorrect Old Password, Please retype your password";
            this.showProgress = false;
            return;
              }
        
      else {
        this.apiResponseError = true;
        this.errorMessage = "Authentication Failed";
        this.showProgress = false;
      }
    },
    error => {
     var errorMsg =  JSON.parse(localStorage.getItem('handle_User'));
     
      this.apiResponseError = true;
      
      if(errorMsg.error.text != undefined && errorMsg.error.text != null && errorMsg.error.text != ''){
        this.showProgress = false;
       
        this.openSuccessSwal();
      }
      else{
      this.errorMessage = "Invalid entry, Please try again with valid entries";
      if (error === 'Server Error') {
        this.errorMessage = "Sorry, Couldn't reach server! Please try again later";
      }
      this.showProgress = false;
    }
    });
    
  } else {
         this.errorMessage = "Form Data Invalid";
        this.showProgress = false;
    }

  }
}
