import { RestDataSource } from "app/model/rest.datasource";
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from "@angular/platform-browser";
import { Router } from "@angular/router";

@Component({
  selector: "App-campaigngeography",
  templateUrl: "./campaigngeography.component.html",
  styleUrls: ["./campaigngeography.component.scss"],
})
export class CampaignGeographyComponent implements OnInit {
  lineitemid: any;
  constructor(
    private restDataSource: RestDataSource,
    private fb: FormBuilder,
    public sanitizer: DomSanitizer,
    private router: Router
  ) {}
  showmap: boolean = false;
  bounds: boolean = false;
  setZoom: any = [];
  result: any;
  polygon: any;
  fitBounds: boolean = false;
  pathsOfPolyArr: any = [];
  fitZoomLevel: any = [];
  geographyMapData: any = [];
  geoDescription: any = "";
  stadiumlatitude = "";
  stadiumlongitude = "";
  @Input() lineitemidFromParent: any;
  update_CampaignGeography(data1): void {
    this.GetCampaignGeographyDetails(data1);
  }
  ngOnInit() {
    //this.geteographyList();
  }
  GetCampaignGeographyDetails(data1) {
    this.lineitemid = data1;

    this.geteographyList();
  }
  // start
  geteographyList() {

    // Latest Map Code
    this.showmap = false;
    this.bounds = false;
    this.fitBounds = false;
    this.result = [];
    this.setZoom = [];
    this.polygon = null;
    // this.pathsOfPolyArr = [];
    // this.fitZoomLevel=[];
    this.geographyMapData = [];
    this.geoDescription = "";
    this.restDataSource
      .getGeographyByLineItemId(this.lineitemid)
      .subscribe((data) => {
        var zipCode = "";
        if (data != null && data != undefined) {
          if (data.length > 0) {
            this.stadiumlatitude = data[0].Stadium_latitude;
            this.stadiumlongitude = data[0].stadium_longitude;
            var status = "";
            status = data[0].Status;
            if (status == "Publish") {
              if (
                data[0].geographyStatus == "zipCode" ||
                data[0].geographyStatus == "State" ||
                data[0].geographyStatus == "DMA" ||
                data[0].geographyStatus == "City" ||
                data[0].geographyStatus == "Country"
              ) {
                this.result = data;
                let i = 0;
                let Mlen = this.result.length;
                while (i < Mlen) {
                  this.polygon = Function("return" + this.result[i].Polygon)();
                  this.result[i].Polygon = this.polygon;
                  let m = 0;
                  let Polylength = this.polygon.length;
                  while (m < Polylength) {
                    let j = 0;
                    let len = this.polygon[m].length;
                    if (data[0].geographyStatus == "Country") {
                      while (j < len) {
                        this.setZoom.push(this.polygon[m][j]);
                        j++;
                        if (len > 5) {
                          break;
                        }
                      }
                    } else {
                      while (j < len) {
                        this.setZoom.push(this.polygon[m][j]);
                        j++;
                        if (Mlen > 5) {
                          break;
                        }
                      }
                    }
                    m++;
                  }
                  if (
                    this.result[i].Description != "" &&
                    this.result[i].Description != null
                  ) {
                    this.geoDescription = this.result[i].Description;
                  }
                  i++;
                }
                this.showmap = true;
                this.bounds = true;
                this.fitBounds = true;
              }
            }
          }
        }
      });
  }
  // end
}
