import { Injectable } from "@angular/core";

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  subchildren?: SuperChildrenItems[];
}

export interface SuperChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  children?: ChildrenItems[];
}

const HORIZONTALMENUITEMS: Menu[] = [
  // {
  //   state: "Onboarding",
  //   name: "Onboarding ",
  //   type: "campaignstrategy",
  //   icon: "view_list",
  // },
  // {
  //   state: "campaignstrategy",
  //   name: "Campaign Strategy",
  //   type: "campaignstrategy",
  //   icon: "emoji_objects",
  // },

  // {
  //   state: "assetupload",
  //   name: "Asset Upload",
  //   type: "link",
  //   icon: "backup",
  // },
  // {
  //   state: "creative",
  //   name: "Creative",
  //   type: "campaignstrategy",
  //   icon: "brush",
  // },
  // {
  //   state: "pixels",
  //   name: "Pixels ",
  //   type: "campaignstrategy",
  //   icon: "code",
  // },
  
  // {
  //   state: "campaignperformance",
  //   name: "Campaign Performance",
  //   type: "link",
  //   icon: "insert_chart",
  // },
  // {
  //   state: "invoices",
  //   name: "Invoices",
  //   type: "link",
  //   icon: "attach_money",
  // },

 
];
@Injectable()
export class HorizontalMenuItems {
  getAll(): Menu[] {
    return HORIZONTALMENUITEMS;
  }
  add(menu: Menu) {
    // HORIZONTALMENUITEMS.push(Menu);
  }
}
