<div class="row">
  <div class="col-md-12">
    <div class="col-md-6">
      <h4 style="font-weight: bolder; color: #092e56">{{this.PackageName}}</h4>
      <h6 style="color: #092e56; font-weight: 500">
        {{ School_Name }}
      </h6>
      <br />

      <h4 style="font-weight: bolder; color: #092e56">
        <img
          alt=""
          pgRetina="pgRetina"
          src1x="assets/img/Native Reporting/Impressions_Icon.png"
          src2x="assets/img/Native Reporting/Impressions_Icon.png"
          src="assets/img/Native Reporting/Impressions_Icon.png"
          style="height: 35px !important; width: 35px !important"
        />
        &nbsp;ESTIMATED TOTAL IMPRESSIONS
      </h4>
      <h3 style="font-weight: bolder" *ngIf="totalImpressions != null && totalImpressions != undefined && totalImpressions != ''">{{ totalImpressions }}</h3>
      <h3 style="font-weight: bolder" *ngIf="totalImpressions == null || totalImpressions == undefined || totalImpressions == ''">0</h3>
      <br />
      <h4 style="font-weight: bolder; color: #092e56">
        <img
          alt=""
          pgRetina="pgRetina"
          src1x="assets/img/Native Reporting/ROI_Estimated Return_Icon.png"
          src2x="assets/img/Native Reporting/ROI_Estimated Return_Icon.png"
          src="assets/img/Native Reporting/ROI_Estimated Return_Icon.png"
          style="height: 35px !important; width: 35px !important"
        />
        &nbsp;ESTIMATED ROI
      </h4>
      <h3 style="font-weight: bolder" *ngIf="estimatedROI != null && estimatedROI != undefined && estimatedROI != ''">{{ estimatedROI }}X</h3>
      <h3 style="font-weight: bolder" *ngIf="estimatedROI == null || estimatedROI == undefined || estimatedROI == ''">0X</h3>
      <br />
      <h4 style="font-weight: bolder; color: #092e56">
        <img
          alt=""
          pgRetina="pgRetina"
          src1x="assets/img/Native Reporting/ROI_Estimated Return_Icon.png"
          src2x="assets/img/Native Reporting/ROI_Estimated Return_Icon.png"
          src="assets/img/Native Reporting/ROI_Estimated Return_Icon.png"
          style="height: 35px !important; width: 35px !important"
        />
        &nbsp;ESTIMATED RETURN
      </h4>
      <h3 style="font-weight: bolder" *ngIf="estimatedReturn != null && estimatedReturn != undefined && estimatedReturn != ''">{{ estimatedReturn }}</h3>
      <h3 style="font-weight: bolder" *ngIf="estimatedReturn == null || estimatedReturn == undefined || estimatedReturn == ''">0</h3>
      
      <br />
    </div>
    <div class="col-md-6">
      <table style="width: 100% !important">
        <thead>
          <tr>
            <th class="text_allign">
              <span class="text_allign tableheadersize">PRODUCT</span>
            </th>
            <th class="text_allign">
              <span class="text_allign tableheadersize">ESTIMATED IMPRESSIONS</span>&nbsp;
              <div class="tooltip1">
                <i
                  class="fa fa-info-circle"
                  style="font-size: 15px; color: #f8ecec"
                ></i>
                <span class="tooltiptext tooltippositionImpressions"
                  >The estimated number of times your advertising material is viewed and/or received</span
                >
              </div>
            </th>
            <th class="text_allign tableheadersize">
              <span class="text_allign">COUNT</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of productList">
            <td class="productalign">{{ row.product }}</td>
            <td class="text_allign">{{ row.impressions }}</td>
            <td class="text_allign">{{ row.count }}</td>
          </tr>
          <tr *ngIf="productList.length != 0">
            <td class="productalign" style="font-weight: bold">Total</td>
            <td class="text_allign" style="font-weight: bold">
              {{ totalImpressions }}
            </td>
            <td class="text_allign"></td>
          </tr>
          <tr *ngIf="productList.length == 0">
            <td colspan="3" style="text-align: center">
              <h4 style="border: 1px solid; padding: 1px">NO DATA</h4>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
