import { RestDataSource } from "app/model/rest.datasource";
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from "@angular/platform-browser";
import { Router } from "@angular/router";

@Component({
  selector: "App-yourpackage",
  templateUrl: "./yourpackage.component.html",
  styleUrls: ["./yourpackage.component.scss"],
})
export class YourPackageComponent implements OnInit {
  estimatedReturn: any;
  estimatedROI: any;
  totalImpressions: any;
  productList: any = [];
  School_Name: any;
  LineItemName : any;
  PackageName : any;
  Sponsor : any;
  constructor(
    private restDataSource: RestDataSource,
    private fb: FormBuilder,
    public sanitizer: DomSanitizer,
    private router: Router
  ) {}
  @Input() lineitemidFromParent: any;
  @Output() SponsorNameToParent : EventEmitter<any> = new EventEmitter();

  update_YourPackage(data1): void {
    this.GetYourPackageDetails(data1);
  }
  ngOnInit() {}
  GetYourPackageDetails(data1) {
    this.restDataSource
      .CampusBoxGetYourPackageDetails(data1)
      .subscribe((data) => {
        var a = [];
          // a = data[0].impressions;
        this.productList = data;

        if(data != undefined && data.length > 0){
          this.estimatedReturn = data[0].estimatedreturn;
          this.estimatedROI = data[0].estimatedroi;
          this.totalImpressions = data[0].totalimpressions;
          this.School_Name = data[0].School_Name;
          this.LineItemName = data[0].lineitemname;
          this.PackageName = data[0].PackageName;
          this.Sponsor = data[0].Sponsor;
          // this.Sponsor.emit(this.Sponsor);
        }else{
          this.estimatedReturn = 0;
          this.estimatedROI =  0;
          this.totalImpressions =  0;
          this.School_Name = '';
          this.LineItemName = '';
          this.PackageName = '';
          this.Sponsor = '';
          // this.SponsorNameToParent.emit('');
        }
     
      });
  }
}
