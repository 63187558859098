//import { DocumentsModel } from './../shared/Documents/DocumentsModel';
import { Injectable } from "@angular/core";
// import { Request, RequestMethod, Response, Headers, RequestOptions } from '@angular/common/http';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/observable/throw";
import "rxjs/add/operator/catch";
import { AppSettings } from "../app.settings";
import { UserProfile } from "./UserProfile";
import { UserRoles } from "./UserRoles";
import { catchError } from "rxjs/operators/catchError";
import { AnonymousSubject } from "rxjs/Rx";
import { json } from "d3";
import { JsonPipe } from "@angular/common";
import { of } from "rxjs";

const PROTOCOL = "http";
const PORT = 3500;

@Injectable()
export class RestDataSource {
  baseUrl: string;
  auth_token: string;
  refresh_token: string;
  Name: string;
  currentUser: string;
  currentUserPrivilege: string;
  UserRoles: UserRoles[];
  displayableId: any = [];
  user: string;

  constructor(private http: HttpClient) {
    this.baseUrl = AppSettings.API_ENDPOINT;
    this.auth_token = localStorage.getItem("auth_token");
    this.currentUser = localStorage.getItem("currentUser");
    this.currentUserPrivilege = localStorage.getItem("currentUserPrivilege");
  }

  loginAuthenticate(model: any): Observable<any> {
    model.grant_type = "password";
    const body = JSON.stringify(model);
    const creds =
      "grant_type=password&email=" +
      model.email +
      "&password=" +
      model.password;

    const httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const url = AppSettings.API_ENDPOINT + "/appUsers/login";

    return this.http
      .post(url, creds, httpOptions)
      .map((response: any) => {
        const r = response;

        this.auth_token = r.id ? r.id : null;

        const Currentdate = new Date();
        Currentdate.setSeconds(Currentdate.getSeconds() + r.expires_in);
        const expiresAt = JSON.stringify(Currentdate);
        localStorage.setItem("auth_token", this.auth_token);
        localStorage.setItem("expires_at", expiresAt);
        return true;
      })
      .catch(this.handleError);
  }

  getTactics(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    };
    const url =
      AppSettings.API_ENDPOINT +
      "/CampaignGeographies/Tactics?access_token=" +
      this.auth_token;
    return this.http
      .post(url, data, httpOptions)
      .map((response: any) => {
        return response;
      })
      .catch(this.handleError);
  }

  getDMAListbyFlight(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    };
    const url =
      AppSettings.API_ENDPOINT +
      "/CampaignGeographies/getDMAListbyFlight?access_token=" +
      this.auth_token;
    return this.http
      .post(url, data, httpOptions)
      .map((response: any) => {
        return response;
      })
      .catch(this.handleError);
  }

  getexportData(data): Observable<any> {
      
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    };
    const url =
      AppSettings.API_ENDPOINT +
      "/CampaignGeographies/getexportData?access_token=" +
      this.auth_token;
    return this.http
      .post(url, data, httpOptions)
      .map((response: any) => {
        return response;
      })
      .catch(this.handleError);
  }

  getBannerAdAndCtvInfoData(lineItemId, flight, userId): Observable<any> {
    this.auth_token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const url =
      AppSettings.API_ENDPOINT +
      "/Campaigns/getBannerAdAndCtvInfoData?access_token=" +
      this.auth_token +
      "&lineItemData=" +
      lineItemId +
      "&flightData=" +
      flight +
      "&LoggedInUserId=" +
      userId;
    return this.http
      .post(url, "", httpOptions)
      .map((response: any) => {
        const r = response;
        return r;
      })
      .catch(this.handleError);
  }

  updateLastLoggedIn(id, LastLoggedIn): Observable<any> {
      

    this.auth_token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const url =
      AppSettings.API_ENDPOINT +
      "/DashboardTacts/updateLastLoggedIn?access_token=" +
      this.auth_token +
      "&id=" +
      id +
      "&LastLoggedIn=" +
      LastLoggedIn;

    return this.http
      .post(url, "", httpOptions)
      .map((response: any) => {
        const r = response;
          

        return r;
      })
      .catch(this.handleError);
  }
  sendingEmails(email): Observable<any> {
      

    this.auth_token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const url =
      AppSettings.API_ENDPOINT +
      "/DashboardTacts/sendingEmails?access_token=" +
      this.auth_token +
      "&email=" +
      email;

    return this.http
      .post(url, "", httpOptions)
      .map((response: any) => {
        const r = response;
          

        return r;
      })
      .catch(this.handleError);
  }
  insertLastLoggedIn(id, LastLoggedIn): Observable<any> {
      

    this.auth_token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const url =
      AppSettings.API_ENDPOINT +
      "/DashboardTacts/insertLastLoggedIn?access_token=" +
      this.auth_token +
      "&id=" +
      id +
      "&LastLoggedIn=" +
      LastLoggedIn;

    return this.http
      .post(url, "", httpOptions)
      .map((response: any) => {
        const r = response;
          

        return r;
      })
      .catch(this.handleError);
  }

  InsertUserToBrandLine(
    id,
    LineItemId,
    LineItemName,
    createdDate,
    modifiedDate
  ): Observable<any> {
      

    this.auth_token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const url =
      AppSettings.API_ENDPOINT +
      "/DashboardTacts/InsertUserToBrandLine?access_token=" +
      this.auth_token +
      "&id=" +
      id +
      "&LineItemId=" +
      LineItemId +
      "&LineItemName=" +
      LineItemName +
      "&createddate=" +
      createdDate +
      "&modifieddate=" +
      modifiedDate;

    return this.http
      .post(url, "", httpOptions)
      .map((response: any) => {
        const r = response;
          

        return r;
      })
      .catch(this.handleError);
  }
  InsertUserToBrandLine_Intermediate(
    id,
    firstNameUser,
    LastNameUser,
    email,
    lineItemNamesEdit,
    userRole,
    status,
    ConversionFlag,
    clientlogo,
    serviceTerm,
    paymentTerm,
    exemptAllTerm
  ): Observable<any> {
    this.auth_token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const url =
      AppSettings.API_ENDPOINT +
      "/DashboardTacts/InsertUserToBrandLine_Intermediate?access_token=" +
      this.auth_token +
      "&id=" +
      id +
      "&firstNameUser=" +
      firstNameUser +
      "&LastNameUser=" +
      LastNameUser +
      "&email=" +
      email +
      "&lineItemNamesEdit=" +
      lineItemNamesEdit +
      "&userRole=" +
      userRole +
      "&status=" +
      status +
      "&ConversionFlag=" +
      ConversionFlag +
      "&clientlogo=" +
      clientlogo +
      "&serviceTerm=" +
      serviceTerm +
      "&paymentTerm=" +
      paymentTerm +
      "&exemptAllTerm=" +
      exemptAllTerm;

    return this.http
      .post(url, "", httpOptions)
      .map((response: any) => {
        const r = response;
          

        return r;
      })
      .catch(this.handleError);
  }
  UpdateLineItemData(
    id,
    email,
    firstName,
    lastName,
    lineItemNamesEdit,
    userRole,
    status,
    modifiedDate,
    ConversionFlag,
    ClientLogo,
    serviceTerm,
    paymentTerm,
    exemptAllTerm
  ): Observable<any> {
      

    this.auth_token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const url =
      AppSettings.API_ENDPOINT +
      "/DashboardTacts/UpdateLineItemData?access_token=" +
      this.auth_token +
      "&id=" +
      id +
      "&email=" +
      email +
      "&firstName=" +
      firstName +
      "&lastName=" +
      lastName +
      "&lineItemNamesEdit=" +
      lineItemNamesEdit +
      "&userRole=" +
      userRole +
      "&status=" +
      status +
      "&modifieddate=" +
      modifiedDate +
      "&ConversionFlag=" +
      ConversionFlag +
      "&ClientLogo=" +
      ClientLogo +
      "&serviceTerm=" +
      serviceTerm +
      "&paymentTerm=" +
      paymentTerm +
      "&exemptAllTerm=" +
      exemptAllTerm;

    return this.http
      .post(url, "", httpOptions)
      .map((response: any) => {
        const r = response;
          

        return r;
      })
      .catch(this.handleError);
  }

  ListOfLineItemsById(id): Observable<any> {
      

    this.auth_token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const url =
      AppSettings.API_ENDPOINT +
      "/DashboardTacts/ListOfLineItemsById?access_token=" +
      this.auth_token +
      "&id=" +
      id;

    return this.http
      .post(url, "", httpOptions)
      .map((response: any) => {
        const r = response;
          

        return r;
      })
      .catch(this.handleError);
  }
  ListOfBciFilesImported(): Observable<any> {
      

    this.auth_token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const url =
      AppSettings.API_ENDPOINT +
      "/DashboardTacts/ListOfBciFilesImported?access_token=" +
      this.auth_token;

    return this.http
      .post(url, "", httpOptions)
      .map((response: any) => {
        const r = response;
          

        return r;
      })
      .catch(this.handleError);
  }
  updateUserToBrandLine(id): Observable<any> {
      

    this.auth_token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const url =
      AppSettings.API_ENDPOINT +
      "/DashboardTacts/updateUserToBrandLine?access_token=" +
      this.auth_token +
      "&id=" +
      id;

    return this.http
      .post(url, "", httpOptions)
      .map((response: any) => {
        const r = response;
          

        return r;
      })
      .catch(this.handleError);
  }
  detailsPageFilters(
    brandId,
    dayReceivedFrom,
    dayReceivedTo,
    FStartDate,
    FEndDate,
    pageNumber,
    pageLimit
  ): Observable<any> {
      

    this.auth_token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const url =
      AppSettings.API_ENDPOINT +
      "/DashboardTacts/dayReceived?access_token=" +
      this.auth_token +
      "&brandId=" +
      brandId +
      "&dayReceivedFrom=" +
      dayReceivedFrom +
      "&dayReceivedTo=" +
      dayReceivedTo +
      "&FStartDate=" +
      FStartDate +
      "&FEndDate=" +
      FEndDate +
      "&pageNumber=" +
      pageNumber +
      "&pageLimit=" +
      pageLimit;
    return this.http
      .get(url)
      .map((response: any) => {
        const r = response;
        const body1 = response;
          
        return body1;
      })
      .catch(this.handleError);
  }

  ManageBillablesPageFilters(
    lineitemId,
    Month,
    pageNumber,
    pageLimit
  ): Observable<any> {
    this.auth_token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const url =
      AppSettings.API_ENDPOINT +
      "/DashboardTacts/managebillablesfilter?access_token=" +
      this.auth_token +
      "&lineitemId=" +
      lineitemId +
      "&Month=" +
      Month +
      "&pageNumber=" +
      pageNumber +
      "&pageLimit=" +
      pageLimit;
    return this.http
      .get(url)
      .map((response: any) => {
        const r = response;
        const body1 = response;
          
        return body1;
      })
      .catch(this.handleError);
  }
  usermanagementsearch(email, name, brand, status): Observable<any> {
    this.auth_token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const url =
      AppSettings.API_ENDPOINT +
      "/DashboardTacts/usermanagementsearch?access_token=" +
      this.auth_token +
      "&email=" +
      email +
      "&name=" +
      name +
      "&brand=" +
      brand +
      "&status=" +
      status;
    return this.http
      .get(url)
      .map((response: any) => {
        const r = response;
        const body1 = response;
          
        return body1;
      })
      .catch(this.handleError);
  }
  getAllBNamesDropDown(): Observable<any> {
      

    const url =
      AppSettings.API_ENDPOINT + "/Brands/swap?access_token=" + this.auth_token;
    return this.http
      .get(url)
      .map((response: any) => {
        const r = response;
        const body1 = response;
          
        return body1;
      })
      .catch(this.handleError);
  }
  getAllLineItemDropDown(): Observable<any> {
      

    const url =
      AppSettings.API_ENDPOINT +
      "/Brands/lineitemname?access_token=" +
      this.auth_token;
    return this.http
      .get(url)
      .map((response: any) => {
        const r = response;
        const body1 = response;
          
        return body1;
      })
      .catch(this.handleError);
  }

  getPowerBIToken(): Observable<any> {
    const url =
      AppSettings.API_ENDPOINT +
      "/Tactics/accesstoken?access_token=" +
      this.auth_token;
    return this.http
      .get(url)
      .map((response: any) => {
        const r = response;
        const body1 = response;
          
        return body1;
      })
      .catch(this.handleError);
  }

  getDashboardData(
    sortFlag,
    sortDayReceivedFrom,
    sortDayReceivedTo,
    sortFStartDate,
    sortFEndDate,
    pageNumber,
    pageLimit
  ): Observable<any> {
    const url =
      AppSettings.API_ENDPOINT +
      "/Tactics/tacticfullload?access_token=" +
      this.auth_token +
      "&sortFlag=" +
      sortFlag +
      "&sortDayReceivedFrom=" +
      sortDayReceivedFrom +
      "&sortDayReceivedTo=" +
      sortDayReceivedTo +
      "&sortFStartDate=" +
      sortFStartDate +
      "&sortFEndDate=" +
      sortFEndDate +
      "&pageNumber=" +
      pageNumber +
      "&pageLimit=" +
      pageLimit;
    return this.http
      .get(url)
      .map((response: any) => {
        const r = response;
        const body1 = response;
          
        return body1;
      })
      .catch(this.handleError);
  }
  getUserDetailsByUserId(UserId): Observable<any> {
    const url =
      AppSettings.API_ENDPOINT +
      "/Tactics/getUserDetailsByUserId?access_token=" +
      this.auth_token +
      "&UserId=" +
      UserId;
    return this.http
      .get(url)
      .map((response: any) => {
        const r = response;
        const body1 = response;
          
        return body1;
      })
      .catch(this.handleError);
  }

  getUserDetails(): Observable<any> {
      
    this.user = localStorage.getItem("currentUser");
    const user1 = JSON.parse(this.user);

    const header = new Headers();

    header.append("content-type", "application/x-www-form-urlencoded");
    header.append("Authorization", "bearer " + this.auth_token + "");

    const auth_token = localStorage.getItem("auth_token");

    const url =
      AppSettings.API_ENDPOINT +
      "/appUsers?access_token=" +
      auth_token +
      '&filter={"where":{"email": "' +
      user1[0].email +
      '"}}';
    return this.http
      .get(url)
      .map((response: any) => {
        const r = response;
        const body1 = response;
          

        return body1;
      })
      .catch(this.handleError);
  }
  isEmailExists(email): Observable<any> {
      
    this.user = localStorage.getItem("currentUser");
    const header = new Headers();

    header.append("content-type", "application/x-www-form-urlencoded");
    header.append("Authorization", "bearer " + this.auth_token + "");

    const auth_token = localStorage.getItem("auth_token");

    const url =
      AppSettings.API_ENDPOINT +
      "/appUsers?access_token=" +
      auth_token +
      '&filter={"where":{"Office365LoginId": "' +
      email +
      '", "Status": "Active"}}';
    return this.http
      .get(url)
      .map((response: any) => {
        const r = response;
        const body1 = response;
          
        //  localStorage.clear();
        localStorage.setItem("currentUser", JSON.stringify(body1));
        localStorage.setItem("UserRole", body1[0].userRole);

        return body1;
      })
      .catch(this.handleError);
  }
  isNormalEmailExists(email): Observable<any> {
      
    //this.user = localStorage.getItem('currentUser');
    const header = new Headers();

    header.append("content-type", "application/x-www-form-urlencoded");
    header.append("Authorization", "bearer " + this.auth_token + "");

    const auth_token = localStorage.getItem("auth_token");

    const url =
      AppSettings.API_ENDPOINT +
      "/appUsers?access_token=" +
      auth_token +
      '&filter={"where":{"email": "' +
      email +
      '", "Status": "Active"}}';
    return this.http
      .get(url)
      .map((response: any) => {
        const r = response;
        const body1 = response;
          
        // localStorage.clear();
        localStorage.setItem("currentUser", JSON.stringify(body1));
        localStorage.setItem("UserRole", body1[0].userRole);

        return body1;
      })
      .catch(this.handleError);
  }
  isNormalEmailExistsUser(email): Observable<any> {
      
    this.user = localStorage.getItem("currentUser");
    const header = new Headers();

    header.append("content-type", "application/x-www-form-urlencoded");
    header.append("Authorization", "bearer " + this.auth_token + "");

    const auth_token = localStorage.getItem("auth_token");

    const url =
      AppSettings.API_ENDPOINT +
      "/appUsers?access_token=" +
      auth_token +
      '&filter={"where":{"email": "' +
      email +
      '"}}';
    return this.http
      .get(url)
      .map((response: any) => {
        const r = response;
        const body1 = response;

        localStorage.setItem("UserRole", body1[0].userRole);
        return body1;
      })
      .catch(this.handleError);
  }
  LogOut(): Observable<any> {
      
    this.auth_token = localStorage.getItem("auth_token");

    localStorage.removeItem("displayableId");

    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json",
        // 'Authorization': 'bearer ' + this.auth_token + ''
      }),
    };

    const url =
      AppSettings.API_ENDPOINT +
      "/appUsers/logout?accessToken=" +
      this.auth_token;

    return this.http
      .post(url, "", httpOptions)
      .map((response: any) => {
          
        return true;
      })
      .catch(this.handleError);
  }
  ChangePasswordService(data: any): Observable<any> {
    const body = data;
    const model: any = {};
    model.oldPassword = data.OldPassword;
    model.newPassword = data.NewPassword;
    // const creds = 'oldPassword=' + data.OldPassword + '&newPassword=' + data.NewPassword + '';
    //+'&oldPassword='+data.OldPassword+'&newPassword='+data.NewPassword
    const url =
      AppSettings.API_ENDPOINT +
      "/appUsers/change-password?access_token=" +
      this.auth_token;

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
        // 'Authorization': 'bearer ' + this.auth_token + ''
      }),
    };
    return (
      this.http
        .post(url, model, httpOptions)
        // .map(this.extractData)
        .map((response: any) => {
            
        })
        .catch(this.handleError)
    );
  }

  ResetPasswordService(newPassword, token): Observable<any> {
    const model: any = {};
      
    model.newPassword = newPassword;
    const authtoken = localStorage.getItem("auth_token");
    const url =
      AppSettings.API_ENDPOINT +
      "/appUsers/reset-password?access_token=" +
      token;

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    };
    return this.http
      .post(url, model, httpOptions)
      .map((response: any) => {
          
        if (response == null) {
          response = true;
        } else {
          response = false;
        }
        return response;
      })
      .catch(this.handleError);
  }

  ForgotPasswordService(email): Observable<any> {
      

    const url = AppSettings.API_ENDPOINT + "/appUsers/reset";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    };
    return this.http
      .post(url, email, httpOptions)
      .map((response: any) => {
          
      })
      .catch(this.handleError);
  }

  // // end
  private handleError(error: any) {
    // console.error(error);
      

    localStorage.setItem("handle_User", JSON.stringify(error));
    if (error.statusText === "Unauthorized") {
      // alert('Error: Unautherized | url = ' + error.url);
    }
    return Observable.throw(error.error || "Server error");
  }

  async validaterequestedurl(data) {
    try {
        
      const url =
        AppSettings.API_ENDPOINT + "/userSharedPages/validaterequestedurl";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + data._token,
        },
        body: JSON.stringify(data),
      });
      const returnValue = await response.json();
      return returnValue;
    } catch (error) {
      return { error: { statusCode: 500 } };
    }
  }

  AdditionalRevisionEmailToClient(email: any, subject: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    };

    const authtoken = localStorage.getItem("auth_token");
    // const url = AppSettings.API_ENDPOINT+'/appUsers/SendRevisionMailCampaignstrategy?access_token='+ SendMailToClient
    const url =
      AppSettings.API_ENDPOINT +
      "/appUsers/AdditionalRevisionEmailToClient?access_token=" +
      this.auth_token +
      "&email=" +
      email +
      "&subject=" +
      subject;
    return this.http
      .get(url)
      .map((response: any) => {
        const r = response;
        const body1 = response;
        return body1;
      })
      .catch(this.handleError);
  }

  SendRevisionMailCampaignStrategy(
    data,
    text,
    lineitemName,
    templateId,
    subject
  ): Observable<any> {
      
    let model: any = {};
    model = data;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    };

    const authtoken = localStorage.getItem("auth_token");
    const url =
      AppSettings.API_ENDPOINT +
      "/appUsers/SendRevisionMailCampaignstrategy?access_token=" +
      this.auth_token +
      "&email=" +
      model.email +
      "&firstName=" +
      model.firstName +
      "&lastName= " +
      model.lastName +
      "&feedbackText= " +
      text +
      "&lineItemName=" +
      lineitemName +
      "&templateId=" +
      templateId +
      "&subject=" +
      subject +
      "&creativetype=" +
      model.creativetype;
    return this.http
      .get(url)
      .map((response: any) => {
        const r = response;
        const body1 = response;
          
        return body1;
      })
      .catch(this.handleError);
  }

  getPaymentStatusDetails(id): Observable<any> {
      

    //  const url = AppSettings.API_ENDPOINT + '/CampaignStrategies/?filter={"where":{"lineItemId":"' + id + '"}}';
    const url =
      AppSettings.API_ENDPOINT +
      "/CampaignStrategies/GetPaymentStatus?access_token=" +
      this.auth_token +
      "&id=" +
      id;

    return this.http
      .get(url)
      .map((response: any) => {
        const r = response;
        const body1 = response;

          
        return body1;
      })
      .catch(this.handleError);
  }
  getTermandCondition(): Observable<any> {
    let model: any = {};
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    };
    const authtoken = localStorage.getItem("auth_token");
    const url =
      AppSettings.API_ENDPOINT +
      "/CampaignTermConditions?access_token=" +
      this.auth_token;
    return this.http
      .get(url)
      .map((response: any) => {
        const r = response;
        const body1 = response;
          
        return body1;
      })
      .catch(this.handleError);
  }

  getUserTermandConditionCheck(id): Observable<any> {
    let model: any = {};
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    };
    const authtoken = localStorage.getItem("auth_token");
    const url =
      AppSettings.API_ENDPOINT +
      "/CampaignTermConditions/GetTermsDetails?access_token=" +
      this.auth_token +
      "&id=" +
      id;
    return this.http
      .get(url)
      .map((response: any) => {
        const r = response;
        const body1 = response;
          
        return body1;
      })
      .catch(this.handleError);
  }
  getTermandConditionAdmin(id, value): Observable<any> {
    let model: any = {};
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    };
    const authtoken = localStorage.getItem("auth_token");
    const url =
      AppSettings.API_ENDPOINT +
      "/CampaignTermConditions/" +
      value +
      "?access_token=" +
      this.auth_token +
      "&id=" +
      id;
    return this.http
      .get(url)
      .map((response: any) => {
        const r = response;
        const body1 = response;
          
        return body1;
      })
      .catch(this.handleError);
  }

  SaveorUpdateUserTermandCondition(data): Observable<any> {
      
    let model: any = {};

    model = data;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    };
    const authtoken = localStorage.getItem("auth_token");
    const url =
      AppSettings.API_ENDPOINT +
      "/CampaignUserTermConditions/replaceOrCreate?access_token=" +
      this.auth_token;
    return this.http
      .post(url, model, httpOptions)
      .map((response: any) => {
        const r = response;
        const body1 = response;
          
        return body1;
      })
      .catch(this.handleError);
  }

  ListOfLineItemsByLineId(id): Observable<any> {
    this.auth_token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const url =
      AppSettings.API_ENDPOINT +
      "/DashboardTacts/GetListByLineItemId?access_token=" +
      this.auth_token +
      "&id=" +
      id +
      "&Environment=" +
      AppSettings.Environment;

    return this.http
      .post(url, "", httpOptions)
      .map((response: any) => {
        const r = response;

        return r;
      })
      .catch(this.handleError);
  }

  getGeneratedURLData(id, boardingId, creativeType): Observable<any> {
      

    const url =
      AppSettings.API_ENDPOINT +
      "/ExternalCreatives/getgeneratedURLData?access_token=" +
      this.auth_token +
      "&id=" +
      id +
      "&boardingId=" +
      boardingId +
      "&creativeType=" +
      creativeType;
    return this.http
      .get(url)
      .map((response: any) => {
        const r = response;
        const body1 = response;
          
        return body1;
      })
      .catch(this.handleError);
  }
  updateGeneratedURL(id, creativeType, flag): Observable<any> {
      

    const url =
      AppSettings.API_ENDPOINT +
      "/ExternalCreatives/updateGeneratedURL?access_token=" +
      this.auth_token +
      "&id=" +
      id +
      "&creativeType=" +
      creativeType +
      "&flag=" +
      flag;
    return this.http
      .get(url)
      .map((response: any) => {
        const r = response;
        const body1 = response;
          
        return body1;
      })
      .catch(this.handleError);
  }
  //share creative end

  BannerPerformanceByDevice(): Observable<any> {
    this.auth_token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const url =
      AppSettings.API_ENDPOINT +
      "/Campaigns/BannerPerformanceByDevice?access_token=" +
      this.auth_token;

    return this.http
      .post(url, "", httpOptions)
      .map((response: any) => {
        const r = response;
        //  

        return r;
      })
      .catch(this.handleError);
  }

  BannerTopDomains(): Observable<any> {
    this.auth_token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const url =
      AppSettings.API_ENDPOINT +
      "/Campaigns/BannerTopDomains?access_token=" +
      this.auth_token;

    return this.http
      .post(url, "", httpOptions)
      .map((response: any) => {
        const r = response;
        //  

        return r;
      })
      .catch(this.handleError);
  }

  ListOfFlightsById(id, camps): Observable<any> {
    //  

    // this.auth_token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const url =
      AppSettings.API_ENDPOINT +
      "/Campaigns/ListOfFlightsById?id=" +
      id +
      "&camps=" +
      camps;

    return this.http
      .post(url, "", httpOptions)
      .map((response: any) => {
        const r = response;
        //  

        return r;
      })
      .catch(this.handleError);
  }
  NativeListOfLineItemsById(id): Observable<any> {
    //  

    this.auth_token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const url =
      AppSettings.API_ENDPOINT +
      "/Campaigns/CampusBoxNativeListOfLineItemsById?access_token=" +
      this.auth_token +
      "&id=" +
      id;

    return this.http
      .post(url, "", httpOptions)
      .map((response: any) => {
        const r = response;
        //  

        return r;
      })
      .catch(this.handleError);
  }
  GetBannerAdPerformanceByDeviceData(
    lineItemId,
    flight,
    UserId
  ): Observable<any> {
    //  

    this.auth_token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const url =
      AppSettings.API_ENDPOINT +
      "/Campaigns/CampusBoxGetBannerAdPerformanceByDeviceData?access_token=" +
      this.auth_token +
      "&lineItemData=" +
      lineItemId +
      "&flightData=" +
      flight +
      "&LoggedInUserId=" +
      UserId;

    return this.http
      .post(url, "", httpOptions)
      .map((response: any) => {
        const r = response;
        //  

        return r;
      })
      .catch(this.handleError);
  }

  //Get HeatMapMediaTypes  - Narasimha
  getHeatMapMediaTypes(data): Observable<any> {
      
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    };
    const url =
      AppSettings.API_ENDPOINT +
      "/CampaignGeographies/getMediaTypes?access_token=" +
      this.auth_token;
    return this.http
      .post(url, data, httpOptions)
      .map((response: any) => {
        return response;
      })
      .catch(this.handleError);
  }

  getHeatMapDetails(data): Observable<any> {
      
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    };
    const url =
      AppSettings.API_ENDPOINT +
      "/CampaignGeographies/getHeatMapDetails?access_token=" +
      this.auth_token;
    return this.http
      .post(url, data, httpOptions)
      .map((response: any) => {
        return response;
      })
      .catch(this.handleError);
  }

  GetTopDomainsDataList(lineItemId, flight, userId): Observable<any> {
    this.auth_token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const url =
      AppSettings.API_ENDPOINT +
      "/Campaigns/CampusBoxGetTopDomainsData?access_token=" +
      this.auth_token +
      "&lineItemData=" +
      lineItemId +
      "&flightData=" +
      flight +
      "&LoggedInUserId=" +
      userId;
    return this.http
      .post(url, "", httpOptions)
      .map((response: any) => {
        const r = response;
        return r;
      })
      .catch(this.handleError);
  }

  GetBannerAdCreativePerformanceByTacticData(
    lineItemId,
    flight,
    userId
  ): Observable<any> {
    this.auth_token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const url =
      AppSettings.API_ENDPOINT +
      "/Campaigns/GetBannerAdCreativePerformanceByTacticData?access_token=" +
      this.auth_token +
      "&lineItemData=" +
      lineItemId +
      "&flightData=" +
      flight +
      "&LoggedInUserId=" +
      userId;
    return this.http
      .post(url, "", httpOptions)
      .map((response: any) => {
        const r = response;
        return r;
      })
      .catch(this.handleError);
  }

  getBanneradCreativePerformance(id, flight, userId): Observable<any> {
    const url =
      AppSettings.API_ENDPOINT +
      "/CampaignCreatives/getCampusBoxBanneradCreativePerformance?access_token=" +
      this.auth_token +
      "&id=" +
      id +
      "&flightData=" +
      flight +
      "&LoggedInUserId=" +
      userId;
    return this.http
      .get(url)
      .map((response: any) => {
        const r = response;
        const body1 = response;
        //  
        return body1;
      })
      .catch(this.handleError);
  }
  // Campus Box
  getGeographyByLineItemId(lineItemId): Observable<any> {
      

    const url =
      AppSettings.API_ENDPOINT +
      "/CampaignGeographies/CampusBoxListLatLngByLineItemId?access_token=" +
      this.auth_token +
      "&lineItemId=" +
      lineItemId;

    return this.http
      .get(url)
      .map((response: any) => {
        const r = response;
        const body1 = response;
          
        return body1;
      })
      .catch(this.handleError);
  }
  CampusBoxGetYourPackageDetails(lineItemId): Observable<any> {
      

    this.auth_token = localStorage.getItem("auth_token");
    const httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const url =
      AppSettings.API_ENDPOINT +
      "/Campaigns/CampusBoxGetYourPackageDetails?access_token=" +
      this.auth_token +
      "&lineItemData=" +
      lineItemId;

    return this.http
      .post(url, "", httpOptions)
      .map((response: any) => {
        const r = response;
        //  

        return r;
      })
      .catch(this.handleError);
  }

  async validatecampusboxsharedlink(data) {
    try {
        
      const url =AppSettings.API_ENDPOINT + "/userSharedPages/validaterequestedurl";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      });
      const sharedresponse = await response.json();
      return sharedresponse;
    } catch (error) {
      return { error: { statusCode: 500 } };
    }
  }

  async validatecampusboxsharedlinkHexCode(data) {
    try {
        
      const url =AppSettings.API_ENDPOINT + "/userSharedPages/validatecampusboxsharedlink";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      });
      const sharedresponse = await response.json();
      return sharedresponse;
    } catch (error) {
      return { error: { statusCode: 500 } };
    }
  }

}
